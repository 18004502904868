import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService, NotifyService } from '@app/services';
import { PayrollResources } from '../payroll.resources';

interface AmeegoImportError {
    employeeName: string;
    message: string;
}

interface AmeegoImportResponse {
    data: {
        attributes: {
            message: {
                message: string;
                rowErrors: AmeegoImportError[];
            };
        };
    };
}

@Injectable()
export class AmeegoHumiImportService {
    constructor(
        private auth: AuthService,
        private http: HttpClient,
        private notify: NotifyService
    ) {}

    async uploadAmeegoHumiImport(payrollId: number, file: File): Promise<AmeegoImportError[]> {
        const uploadAmeegoHumiImportData = new FormData();
        uploadAmeegoHumiImportData.append('file', file);
        const url = PayrollResources.UploadAmeegoHumiImport.replace(
            ':companyId',
            this.auth.company.id.toString()
        ).replace(':payrollId', payrollId.toString());
        const uploadAmeegoHumiImportResult = await this.http
            .post<AmeegoImportResponse>(url, uploadAmeegoHumiImportData)
            .toPromise();
        const attributes = uploadAmeegoHumiImportResult['data']['attributes']['message'];
        this.notify.success(attributes?.message);
        return attributes['rowErrors'];
    }
}

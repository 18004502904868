import { html, LitElement, nothing, svg, TemplateResult } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { getHorizontalOffset } from './styles';
import { getHorizontalLabel, getVerticalLabel, gradient } from './svgComponents';
import { HumiLogoLabelOrientation, HumiLogoVariant } from './types';

export * from './types';

@customElement('humi-logo')
export class HumiLogo extends LitElement {
    /**
     * The color variations of the logo
     */
    @property()
    variant: HumiLogoVariant = 'color-black-H';

    /**
     * The orientation of the label if present. If undefined, this property will result in the symbol only
     */
    @property()
    labelOrientation?: HumiLogoLabelOrientation;

    /**
     * Only attach the gradient image to the DOM if we have a colorized version of the logo
     */
    private get hasGradient(): boolean {
        return this.variant.includes('color');
    }

    render() {
        let humiTitle: TemplateResult | undefined;

        if (this.labelOrientation) {
            humiTitle = this.labelOrientation === 'horizontal' ? getHorizontalLabel() : getVerticalLabel();
        }

        // Explicitly sets the font color in the logo if the consumer has chosen an explicit white/black logo
        if (this.variant.includes('black')) {
            this.style.setProperty('--humi-logo-font-color', 'var(--humi-logo-font-color-dark)');
        } else if (this.variant.includes('white')) {
            this.style.setProperty('--humi-logo-font-color', 'var(--humi-logo-font-color-light)');
        } else {
            // If the consumer is using the auto coloring then we remove the explicit styling so that the value is instead pulled from the root stylesheet
            this.style.removeProperty('--humi-logo-font-color');
        }

        return html`<svg
            aria-label=${this.ariaLabel}
            width="100%"
            height="100%"
            viewBox="${DIMENSIONS[this.labelOrientation || 'default']}"
            xmlns="http://www.w3.org/2000/svg"
        >
            <!-- Humi title present on certain orientations -->
            ${humiTitle}
            <!-- "H" path always present -->
            <path
                style="${getHorizontalOffset(this.labelOrientation)}"
                d="M146 232.83V319H171V282.5H219V307.17C219 311.22 218.6 315.18 217.84 319H243.18C243.72 315.13 244 311.18 244 307.17V221H219V257.5H171V232.83C171 228.78 171.4 224.82 172.16 221H146.82C146.28 224.87 146 228.82 146 232.83Z"
                fill="var(--humi-logo-font-color)"
            />
            <!-- Colored swirl only attached on non-color versions of logo -->
            ${!this.hasGradient
                ? svg`<path
                    style="${getHorizontalOffset(this.labelOrientation)}"
                    d="M195 0C87.3 0 0 87.3 0 195V345C0 452.7 87.3 540 195 540C302.7 540 390 452.7 390 345V195C390 87.3 302.7 0 195 0ZM365 345C365 438.89 288.89 515 195 515C120.88 515 57.84 467.55 34.59 401.38C62.52 440.76 107.62 466.34 158.5 466.34C246.04 466.34 317 395.38 317 307.84V232.84C317 185.62 278.72 147.34 231.5 147.34C197.47 147.34 168.08 167.22 154.32 196.01H183.5C194.56 181.62 211.94 172.34 231.49 172.34C264.9 172.34 291.99 199.43 291.99 232.84V307.84C291.99 381.57 232.22 441.34 158.49 441.34C84.76 441.34 24.99 381.57 24.99 307.84V195.01C24.99 101.12 101.1 25.01 194.99 25.01C269.11 25.01 332.15 72.46 355.4 138.63C327.47 99.25 282.37 73.67 231.49 73.67C143.95 73.67 72.99 144.63 72.99 232.17V307.17C72.99 354.39 111.27 392.67 158.49 392.67C192.52 392.67 221.91 372.79 235.67 344H206.49C195.43 358.39 178.05 367.67 158.5 367.67C125.09 367.67 98 340.58 98 307.17V232.17C98 158.44 157.77 98.67 231.5 98.67C305.23 98.67 365 158.44 365 232.17V345Z"
                    fill="var(--humi-logo-font-color)"
                />`
                : nothing}
            <!-- Gradient image only attached on color versions of logo -->
            ${this.hasGradient
                ? svg`<rect width="390" height="540" style="${getHorizontalOffset(this.labelOrientation)}" fill="url(#pattern0_185_8871)" />`
                : nothing}
            ${this.hasGradient ? gradient : nothing}
        </svg> `;
    }
}

// Due to the presence of a label or not, the viewbox of the SVG has to be adjusted
const DIMENSIONS: Record<HumiLogoLabelOrientation | 'default', string> = {
    default: '0 0 390 540',
    horizontal: '0 0 1208 540',
    vertical: '0 0 554 786',
};

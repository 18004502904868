import { Component, Input, OnInit } from '@angular/core';
import { PaginationData } from '@app/interfaces';
import { AppDatePipe } from '@app/pipes';
import { Report } from '@reporting/services/queries/report';
import moment from 'moment';
import { TranslatableKey } from '@app/types/translatable.type';
import { NotifyService } from '@app/services';

@Component({
    selector: 'app-report-table',
    templateUrl: './report-table.template.html',
    styleUrls: ['./report-table.style.scss'],
})
export class ReportTableComponent implements OnInit {
    @Input() report: Report;
    @Input() emptyText: TranslatableKey = 'reporting.reports.noDataInReport';
    @Input() scrollable = true;
    @Input() title = '';
    @Input() noBottomBorder = false;
    @Input() showExportButton = false;

    isLoading = false;
    decimalPlacesForPipe = '1.2-2';

    paginationData: PaginationData;
    sortingField: string | null;
    sortingDirection: 'DESC' | 'ASC' | null;

    constructor(private notify: NotifyService) {}

    ngOnInit(): void {
        this.setPaginationData();
    }

    onPageChange(page: number): void {
        if (this.paginationData.page !== page) {
            this.isLoading = true;

            this.report
                .page(page)
                .get()
                .then((report: Report) => {
                    this.report = report;
                    this.setPaginationData();
                    this.isLoading = false;
                });
        }
    }

    onSort(sortField: string): void {
        this.isLoading = true;

        /**
         * On final sort for this field, remove the column sorting.
         */
        if (this.removeSort(sortField)) {
            this.sortingField = null;
            this.sortingDirection = null;
        } else {
            /**
             * if we have a sort direction on same column, invert it
             */
            this.sortingDirection = this.invertSort(sortField) ? 'ASC' : 'DESC';
            this.sortingField = sortField;
        }

        this.report
            .sort(this.sortingField, this.sortingDirection)
            .get()
            .then((report: Report) => {
                this.report = report;
                this.setPaginationData();
                this.isLoading = false;
            });
    }

    setPaginationData(): void {
        this.paginationData = this.report.paginationData;
    }

    getDisplayedColumns(): string[] {
        return this.report.sheet.columns.map((col) => col.title);
    }

    hasRows(): boolean {
        return this.report.sheet && this.report.sheet.rows && this.report.sheet.rows.length > 0;
    }

    displayDateValue(dateValue: any): string {
        const datePipe = new AppDatePipe();

        if (moment(dateValue).isValid()) {
            return datePipe.transform(dateValue, 'mediumDate');
        }

        return dateValue;
    }

    /*
     * It only export in xlsx format currently.
     * */
    onExport(): void {
        this.report.export('xlsx').catch(() => {
            this.notify.error('reporting.reports.errorExportingReport');
        });
    }

    /**
     * If we're re-sorting the same field, and we last sorted by ASC, then this time
     * we remove the field sorting
     */
    protected removeSort(field: string): boolean {
        return this.sortingField === field && this.sortingDirection === 'ASC';
    }

    /**
     * If we're re-sorting the same field, and we last sorted by DESC, then we need to invert to ASC
     */
    protected invertSort(field: string): boolean {
        return this.sortingField === field && this.sortingDirection === 'DESC';
    }
}

import { TranslatableKey } from '@app/types/translatable.type';
import { CalendarEvent } from '@models/common/calendar-event.model';
import { isSameDay, parse } from 'date-fns';
import { Moment } from 'moment';

interface TimeOffCalendarPayload {
    description?: string;
    startAt: Moment | Date;
    endAt: Moment | Date;
    entityColor: string;
    timeOffTypeName: string;
    isPublic?: boolean;
    status?: string;
    avatarId?: number;
    employeeId?: number;
    employeeFullName?: string;
    firstName?: string;
    lastName?: string;
    fullName?: string;
    hasMultipleDays?: boolean;
    totalAmount?: number;
    totalAmountInHours?: number;
    hoursPerDay?: number;
    displayInHours?: boolean;
    type: string;
}

export class TimeOffRequestCalendarEvent extends CalendarEvent {
    eventType = 'request';
    private payload: TimeOffCalendarPayload;

    constructor(res: TimeOffCalendarPayload) {
        super(res);
        this.payload = res;
    }

    get isPublic(): boolean {
        return this.payload.isPublic;
    }

    get fullName(): string {
        return this.payload.employeeFullName;
    }

    get monthCalendarTooltipDescription(): string {
        return this.payload.timeOffTypeName;
    }

    get monthCalendarTitle(): string {
        return this.payload.employeeFullName;
    }

    get upcomingEventDescription(): TranslatableKey {
        const timeOffTypeName = this.payload.timeOffTypeName;

        const sameDay = isSameDay(this.startAt, this.endAt);

        if (sameDay) {
            return this.getSameDayDescription();
        }

        return this.payload.hasMultipleDays ? 'time-off-v3.time-off-events.timeOffMultiDays' : timeOffTypeName;
    }

    get upcomingEventTitle(): TranslatableKey {
        return this.payload.totalAmount && this.payload.totalAmount < 1
            ? 'time-off-v3.time-off-events.timeOffEmployeeAwayForPartOfTheDay'
            : 'time-off-v3.time-off-events.timeOffEmployeeIsAway';
    }

    get employeeId(): number {
        return this.payload.employeeId;
    }

    get dateDescription(): TranslatableKey {
        const sameDay = isSameDay(this.startAt, this.endAt);

        if (sameDay) {
            return this.getSameDayDescription();
        }

        return 'time-off-v3.time-off-events.timeOffdateDescription';
    }

    get isMultiDayEvent(): boolean {
        return !isSameDay(this.startAt, this.endAt);
    }

    get end(): Date {
        return parse(this.endAt);
    }

    get isSameDayEvent(): boolean {
        return isSameDay(this.startAt, this.endAt) && this.type === 'timeOffRequests';
    }

    get getPluralOrSingularHourlyUnit(): TranslatableKey {
        return (this.payload.totalAmountInHours ?? 0 > 1) ? 'time-off-v3.hours' : 'time-off-v3.hourSingular';
    }

    get getPluralOrSingularDayUnit(): TranslatableKey {
        return this.payload.totalAmount === 1 ? 'time-off-v3.daySingular' : 'time-off-v3.days';
    }

    getSameDayDescription(): TranslatableKey {
        return this.isPublic ? 'time-off-v3.time-off-events.timeOffFor' : 'time-off-v3.time-off-events.awayFor';
    }
}

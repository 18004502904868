import { SimpleEntityActions } from '@app/classes';
import { RemitterTypes } from '@app/constants/remitter-types';
import { RqRemittanceFrequencies } from '@app/constants/rq-remittance-frequencies';
import { IntegrationIndeed } from '@app/models/company/settings/integrations/indeed.model';
import { SELF_SERVE_PAYROLL_PLANS, SelfServePlan } from '@app/modules/self-serve/types/plans.types';
import { SetupGuide } from '@models/company/setup-guide.model';
import { Rep } from '../account/rep.model';
import { File } from '../common/file.model';
import { Module } from '../common/module.model';
import { ModelMixin } from '../core/base-generic.model';
import { SelfServeOnboarding } from '../self-serve/self-serve-onboarding.model';
import { SelfServeQuickstart } from '../self-serve/self-serve-quickstart.model';
import { BankAccount } from './bank-account.model';
import { Note } from './note.model';
import { Office } from './office.model';

export class Company extends ModelMixin<Company>() {
    static permission = new SimpleEntityActions('company');

    protected static _resource = 'companyManagement/companies';
    protected static _version = 'v2';
    protected static _serializeAttributes = [
        'attach',
        'name',
        'repEmail',
        'dateJoinedHumi',
        'doingBusinessAsName',
        'isPayrollSyncEnabled',
        'logoId',
        'bankAccountId',
        'industry',
        'incorporationDate',
        'website',
        'notificationEmail',
        'phonePrimary',
        'fax',
        'cardBrand',
        'cardLastFour',
        'suppressAllAlerts',
        'craBusinessNumber',
        'craProgramCode',
        'craReferenceNumber',
        'remitterType',
        'quebecEnterpriseNumber',
        'rqRegistration',
        'rqProgramCode',
        'rqReferenceNumber',
        'rqRemittanceFrequency',
        'brandLogoFileId',
    ];
    protected static _dates = ['dateJoinedHumi', 'incorporationDate'];

    remitterTypes = RemitterTypes;
    rqRemittanceFrequencies = RqRemittanceFrequencies;

    get id(): number {
        return +this._attributes['id'] || +this._attributes['company_id'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get repEmail(): string {
        if (!this.reps.length) {
            return '';
        }

        return this.reps[0].email;
    }

    get organizationId(): number {
        return this._attributes['organizationId'];
    }

    get bankAccountId(): number {
        return this._attributes['bankAccountId'] || this._attributes['bank_account_id'] || null;
    }

    get activeEmployeeCount(): number {
        return this._attributes['activeEmployeeCount'] || 0;
    }

    /**
     * Active, terminated, onboarding employees
     */
    get employeeCount(): number {
        return this._attributes['employeeCount'] || 0;
    }

    get dateJoinedHumi(): Date {
        return this._attributes['dateJoinedHumi'] || null;
    }

    get doingBusinessAsName(): string {
        return this._attributes['doingBusinessAsName'];
    }

    set doingBusinessAsName(val: string) {
        this._attributes['doingBusinessAsName'] = val;
    }

    get isPayrollSyncEnabled(): boolean {
        return this._attributes['isPayrollSyncEnabled'];
    }

    set isPayrollSyncEnabled(val: boolean) {
        this._attributes['isPayrollSyncEnabled'] = val;
    }

    /**
     * @deprecated use brandLogoFileId
     */
    get logoId(): number | null {
        return +this._attributes['logoId'];
    }

    /**
     * @deprecated use brandLogoFileId
     */
    set logoId(val: number) {
        this._attributes['logoId'] = val;
    }

    get industry(): string {
        return this._attributes['industry'];
    }

    set industry(val: string) {
        this._attributes['industry'] = val;
    }

    get incorporationDate(): Date {
        return this._attributes['incorporationDate'];
    }

    set incorporationDate(val: Date) {
        this._attributes['incorporationDate'] = val;
    }

    get website(): string {
        return this._attributes['website'] ?? '';
    }

    set website(val: string) {
        this._attributes['website'] = val;
    }

    get websiteAbsoluteUrl(): string | null {
        const website = this.website;

        if (!website) {
            return null;
        }

        const absoluteUrlRegex = new RegExp('^(?:[a-z]+:)?//', 'i');

        if (absoluteUrlRegex.test(website)) {
            return website;
        }

        return 'https://' + website;
    }

    get notification_email(): string {
        return this._attributes['notificationEmail'];
    }

    set notification_email(val: string) {
        this._attributes['notificationEmail'] = val;
    }

    get fax(): string {
        return this._attributes['fax'];
    }

    set fax(val: string) {
        this._attributes['fax'] = val;
    }

    get phonePrimary(): string {
        return this._attributes['phonePrimary'];
    }

    set phonePrimary(val: string) {
        this._attributes['phonePrimary'] = val;
    }

    get suppressAllAlerts(): boolean {
        return this._attributes['suppressAllAlerts'];
    }

    set suppressAllAlerts(val: boolean) {
        this._attributes['suppressAllAlerts'] = val;
    }

    get notificationEmail(): string {
        return this._attributes['notificationEmail'];
    }

    set notificationEmail(val: string) {
        this._attributes['notificationEmail'] = val;
    }

    get prCompanyId(): number {
        return this._attributes['prCompanyId'];
    }

    set prCompanyId(val: number) {
        this._attributes['prCompanyId'] = val;
    }

    get prTenantId(): string {
        return this._attributes['prTenantId'];
    }

    set prTenantId(val: string) {
        this._attributes['prTenantId'] = val;
    }

    get craBusinessNumber(): string {
        return this._attributes['craBusinessNumber'];
    }

    set craBusinessNumber(val: string) {
        this._attributes['craBusinessNumber'] = val;
    }

    get craProgramCode(): string {
        return this._attributes['craProgramCode'];
    }

    set craProgramCode(val: string) {
        this._attributes['craProgramCode'] = val;
    }

    get craReferenceNumber(): string {
        return this._attributes['craReferenceNumber'];
    }

    set craReferenceNumber(val: string) {
        this._attributes['craReferenceNumber'] = val;
    }

    get remitterType(): string {
        return this._attributes['remitterType'];
    }

    set remitterType(val: string) {
        this._attributes['remitterType'] = val;
    }

    get remitterTypeDisplay(): string {
        return this.remitterTypes[this.remitterType];
    }

    get quebecEnterpriseNumber(): string {
        return this._attributes['quebecEnterpriseNumber'];
    }

    set quebecEnterpriseNumber(val: string) {
        this._attributes['quebecEnterpriseNumber'] = val;
    }

    get rqRegistration(): string {
        return this._attributes['rqRegistration'];
    }

    set rqRegistration(val: string) {
        this._attributes['rqRegistration'] = val;
    }

    get rqProgramCode(): string {
        return this._attributes['rqProgramCode'];
    }

    set rqProgramCode(val: string) {
        this._attributes['rqProgramCode'] = val;
    }

    get rqReferenceNumber(): string {
        return this._attributes['rqReferenceNumber'];
    }

    set rqReferenceNumber(val: string) {
        this._attributes['rqReferenceNumber'] = val;
    }

    get rqRemittanceFrequency(): string {
        return this._attributes['rqRemittanceFrequency'];
    }

    set rqRemittanceFrequency(val: string) {
        this._attributes['rqRemittanceFrequency'] = val;
    }

    get rqRemittanceFrequencyDisplay(): string {
        return this.rqRemittanceFrequencies[this.rqRemittanceFrequency];
    }

    get cardBrand(): string {
        return this._attributes['cardBrand'] || this._attributes['card_brand'];
    }

    get cardLastFour(): string {
        return this._attributes['cardLastFour'] || this._attributes['card_last_four'];
    }

    get simplyBenefitsAccountId(): null | string {
        return this._attributes['simplyBenefitsAccountId'];
    }

    /**
     * @deprecated use brandLogoFileId
     */
    get logo(): File {
        return this.hasOne(File, 'logo');
    }

    /**
     * @deprecated use brandLogoFileId
     */
    set logo(val: File) {
        this.setOne('logo', val, 'logoId');
    }

    get addresses(): Office[] {
        return this.hasMany(Office, 'addresses');
    }

    get primaryAddressId(): number {
        return this._attributes['primaryAddressId'];
    }

    get reps(): Rep[] {
        return this.hasMany(Rep, 'reps');
    }

    get modules(): Module[] {
        return this.hasMany(Module, 'modules');
    }

    get notes(): Note[] {
        return this.hasMany(Note, 'notes');
    }

    get bankAccount(): BankAccount {
        if (!this.bankAccounts.length) {
            return this.tempBankAccount;
        }
        return this.bankAccounts[0];
    }

    set bankAccount(val: BankAccount) {
        this.setOne('bankAccount', val);
    }

    get bankAccounts(): BankAccount[] {
        return this.hasMany(BankAccount, 'bankAccounts', {
            employee: this.id,
        });
    }

    set bankAccounts(val: BankAccount[]) {
        this.setMany('bankAccounts', val);
    }

    get indeedIntegration(): IntegrationIndeed {
        return this.hasOne(IntegrationIndeed, 'indeedIntegration');
    }

    get billableEmployeeCount(): number | null {
        return this._attributes['billableEmployeeCount'];
    }

    get brandLogoFileId(): number | null {
        return this._attributes['brandLogoFileId'];
    }

    set brandLogoFileId(val: number | null) {
        this._attributes['brandLogoFileId'] = val;
    }

    private tempBankAccount: BankAccount = new BankAccount();

    get selfServeQuickstart(): SelfServeQuickstart {
        return this.hasOne(SelfServeQuickstart, 'selfServeQuickstart');
    }

    get selfServeOnboarding(): SelfServeOnboarding {
        return this.hasOne(SelfServeOnboarding, 'selfServeOnboarding');
    }

    /**
     * isSelfServe means that the company has signed up using the self serve flow
     */
    get isSelfServe(): boolean {
        return this._attributes['isSelfServe'];
    }

    get selfServePlan(): SelfServePlan | null {
        return this._attributes['selfServePlan'];
    }

    get setupGuides(): SetupGuide[] {
        return this.hasMany(SetupGuide, 'setupGuides');
    }

    get isSelfServeWithPayroll(): boolean {
        return Boolean(this.isSelfServe && this.selfServePlan && SELF_SERVE_PAYROLL_PLANS.includes(this.selfServePlan));
    }

    get personaCompletedAt(): Date | undefined {
        return this._attributes['personaCompletedAt'];
    }

    get isBenefitsPayrollIntegrationEnabled(): boolean {
        return this._attributes['isBenefitsPayrollIntegrationEnabled'];
    }
}

import { Model } from '../core/base.model';
import { BenefitProvider } from '@models/benefits/benefit-provider.model';
import { BenefitCategory } from '@models/benefits/benefit-category.model';
import { BenefitDetail } from '@models/benefits/benefit-detail.model';
import { File } from '../common/file.model';

export class Benefit extends Model {
    protected static _resource = 'benefitManagement/companies/:company/benefitPlans/:benefitPlan/benefits';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'benefitPlanId',
        'benefitCategoryId',
        'fileId',
        'providerId',
        'policyNumber',
        'description',
        'startAt',
        'expireAt',
        'splitPercentage',
    ];

    protected static _dates = ['startAt', 'expireAt'];

    get description(): string | null {
        return this._attributes['description'];
    }

    set description(val: string | null) {
        this._attributes['description'] = val;
    }

    get policyNumber(): string | null {
        return this._attributes['policyNumber'];
    }

    set policyNumber(val: string | null) {
        this._attributes['policyNumber'] = val;
    }

    get startAt(): Date | null {
        return this._attributes['startAt'];
    }

    set startAt(val: Date | null) {
        this._attributes['startAt'] = val;
    }

    get expireAt(): Date | null {
        return this._attributes['expireAt'];
    }

    set expireAt(val: Date | null) {
        this._attributes['expireAt'] = val;
    }

    get fileId(): number | null {
        return this._attributes['fileId'];
    }

    set fileId(val: number | null) {
        this._attributes['fileId'] = val;
    }

    get providerId(): number | null {
        return this._attributes['providerId'];
    }

    set providerId(val: number | null) {
        this._attributes['providerId'] = val;
    }

    get benefitCategoryId(): number {
        return this._attributes['benefitCategoryId'];
    }

    set benefitCategoryId(val: number) {
        this._attributes['benefitCategoryId'] = val;
    }

    get benefitPlanId(): number {
        return this._attributes['benefitPlanId'];
    }

    set benefitPlanId(val: number) {
        this._attributes['benefitPlanId'] = val;
    }

    get benefitProvider(): BenefitProvider {
        return this.hasOne(BenefitProvider, 'benefitProvider');
    }

    get file(): File {
        return this.hasOne(File, 'file');
    }

    set file(val: File) {
        this.setOne('file', val, 'fileId');
    }

    get benefitCategory(): BenefitCategory {
        return this.hasOne(BenefitCategory, 'benefitCategory');
    }

    set benefitCategory(val: BenefitCategory) {
        this.setOne('benefitCategory', val, 'benefitCategoryId');
    }

    get benefitDetails(): BenefitDetail[] {
        return this.hasMany(BenefitDetail, 'benefitDetails');
    }

    get splitPercentage(): number | null {
        return this._attributes['splitPercentage'];
    }

    set splitPercentage(val: number | null) {
        this._attributes['splitPercentage'] = val;
    }
}

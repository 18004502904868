import { PillType } from '@app/components/platform/pill/types';
import { AppDatePipe } from '@app/pipes';
import { AuthService } from '@app/services';
import { Translatable } from '@app/types/translatable.type';
import { JsonApiResponse } from '@interfaces/json-api-resource.interface';
import { SaveOptions } from '@interfaces/save-options.interface';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { TimeOffRequestApprovalFlow } from '@models/time-off-v3/time-off-request-approval-flow.model';
import { TimeOffRequestCalendarEvent } from '@models/time-off-v3/time-off-request-calendar-event.model';
import { TimeOffRequestDay, TimeOffRequestDayPayload } from '@models/time-off-v3/time-off-request-day.model';
import {
    TimeOffDateFormatDashes,
    TimeOffDateMonthDayDatePipeFormat,
    TimeOffDateMonthDayYearDatePipeFormat,
} from '@time-off-v3/meta/time-off-meta';
import moment from 'moment';
import { TimeOffPolicy } from './time-off-policy.model';

export enum RequestPillTypes {
    approved = 'success',
    pending = 'primary',
    denied = 'danger',
}

export enum TimeOffRequestStatus {
    approved = 'approved',
    pending = 'pending',
    denied = 'denied',
}

// This interface is required since not all model attributes are required to be sent back to backend
interface TimeOffRequestPayload {
    id?: number;
    employeeId: number;
    timeOffPolicyId: number;
    startAt: string;
    endAt: string;
    timeOffRequestDaysPayload: TimeOffRequestDayPayload[];
}

const appDate = new AppDatePipe();

export class TimeOffRequest extends Model {
    protected static _resource = 'timeOffV3/requests';

    protected static _version = 'v2';

    protected static _dates = ['startAt', 'endAt'];

    protected static _type = 'timeOffRequests';

    protected static _serializeAttributes = [
        'id',
        'employeeId',
        'timeOffPolicyId',
        'status',
        'description',
        'startAt',
        'endAt',
        'timeOffRequestDaysPayload',
    ];

    appDatePipe = new AppDatePipe();

    get employeeId(): number {
        return this._attributes['employeeId'];
    }

    get timeOffPolicyId(): number {
        return this._attributes['timeOffPolicyId'];
    }

    get status(): string {
        return this._attributes['status'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    get startAt(): Date {
        return this._attributes['startAt'];
    }

    get endAt(): Date {
        return this._attributes['endAt'];
    }

    get startAtString(): string {
        return appDate.transform(this.startAt, 'MMMM d, yyyy');
    }

    get endAtString(): string {
        return appDate.transform(this.endAt, 'MMMM d, yyyy');
    }

    get totalAmount(): number {
        return this._attributes['totalAmount'];
    }

    get totalAmountHours(): number {
        return this._attributes['totalAmountHours'];
    }

    get timeOffRequestDays(): TimeOffRequestDay[] {
        return this.hasMany(TimeOffRequestDay, 'timeOffRequestDays');
    }

    get timeOffPolicy(): TimeOffPolicy {
        return this.hasOne(TimeOffPolicy, 'timeOffPolicy');
    }

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get timeOffRequestApprovalFlow(): TimeOffRequestApprovalFlow {
        return this.hasOne(TimeOffRequestApprovalFlow, 'timeOffRequestApprovalFlow');
    }

    set timeOffRequestApprovalFlow(value: TimeOffRequestApprovalFlow) {
        this.setOne('timeOffRequestApprovalFlow', value);
    }

    get createdBy(): Employee {
        return this.hasOne(Employee, 'createdBy');
    }

    get approved(): boolean {
        return this.status === TimeOffRequestStatus.approved;
    }

    get pending(): boolean {
        return this.status === TimeOffRequestStatus.pending;
    }

    get hasMultipleDays(): boolean {
        return Math.abs(moment(this.startAt).diff(moment(this.endAt), 'days')) > 0;
    }

    get showHourly(): boolean {
        return this._attributes['displayInHours'];
    }

    get hoursPerDay(): number {
        return this.timeOffPolicy?.timeOffType.hoursPerDay;
    }

    get dateRangeString(): string {
        const startDate = moment(this.startAt);
        const endDate = moment(this.endAt);
        const multiDays = Math.abs(startDate.diff(endDate, 'day')) > 0;

        return multiDays
            ? `${this.appDatePipe.transform(
                  startDate,
                  TimeOffDateMonthDayDatePipeFormat
              )} - ${this.appDatePipe.transform(endDate, TimeOffDateMonthDayYearDatePipeFormat)}`
            : `${this.appDatePipe.transform(endDate, TimeOffDateMonthDayYearDatePipeFormat)}`;
    }

    get totalAmountWithUnit(): Translatable {
        const totalAmount = this.showHourly ? this.totalAmountHours : this.totalAmount;
        const unitOfTime = this.unit;

        if (unitOfTime === 'day') {
            return {
                key: 'time-off-v3.totalAmountWithUnitDay',
                params: {
                    totalAmount: totalAmount.toFixed(2),
                },
            };
        } else if (unitOfTime === 'days') {
            return {
                key: 'time-off-v3.totalAmountWithUnitDays',
                params: {
                    totalAmount: totalAmount.toFixed(2),
                },
            };
        } else if (unitOfTime === 'hour') {
            return {
                key: 'time-off-v3.totalAmountWithUnitHour',
                params: {
                    totalAmount: totalAmount.toFixed(2),
                },
            };
        } else if (unitOfTime === 'hours') {
            return {
                key: 'time-off-v3.totalAmountWithUnitHours',
                params: {
                    totalAmount: totalAmount.toFixed(2),
                },
            };
        }

        return `${totalAmount.toFixed(2)} ${unitOfTime}`;
    }

    get unit(): Translatable {
        if (this.showHourly) {
            return this.totalAmountHours > 1 || this.totalAmountHours === 0
                ? this.unitOfTimePlural
                : this.unitOfTimeSingular;
        }

        return this.totalAmount > 1 ? this.unitOfTimePlural : this.unitOfTimeSingular;
    }

    get unitOfTimePluralTranslation(): Translatable {
        return this.timeOffPolicy?.timeOffType?.unitOfTimePluralTranslation;
    }

    get unitOfTimePlural(): string {
        return this.timeOffPolicy?.timeOffType?.unitOfTimePlural;
    }

    get unitOfTimeSingularTranslation(): Translatable {
        return this.timeOffPolicy?.timeOffType?.unitOfTimeSingularTranslation;
    }

    get unitOfTimeSingular(): string {
        return this.timeOffPolicy?.timeOffType?.unitOfTimeSingular;
    }

    get timeOffRequestDaysPayload(): TimeOffRequestDayPayload[] {
        return this._attributes['timeOffRequestDaysPayload'];
    }

    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }

    set timeOffPolicyId(val: number) {
        this._attributes['timeOffPolicyId'] = val;
    }

    set status(val: string) {
        this._attributes['status'] = val;
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    set startAt(val: Date) {
        this._attributes['startAt'] = val;
    }

    set endAt(val: Date) {
        this._attributes['endAt'] = val;
    }

    set timeOffRequestDays(val: TimeOffRequestDay[]) {
        this.setMany('timeOffRequestDays', val);
    }

    set timeOffPolicy(val: TimeOffPolicy) {
        this.setOne('timeOffPolicy', val);
    }

    set employee(val: Employee) {
        this.setOne('employee', val);
    }

    getPillType(): PillType {
        return RequestPillTypes[this.status];
    }

    canApproveOrDeny(employee: Employee): boolean {
        return (
            this.pending &&
            this.timeOffRequestApprovalFlow.timeOffRequestApprovalFlowSteps
                .find((requestApprovalFlowStep) => requestApprovalFlowStep.approved === null)
                ?.timeOffRequestApprovals.map((requestApproval) => requestApproval.approver.id)
                .includes(employee.id)
        );
    }

    toCalendarEvent(auth: AuthService): TimeOffRequestCalendarEvent {
        const isAccessible =
            this.timeOffPolicy?.timeOffType.isPublic || auth?.isAdmin() || auth?.supervises(this.employeeId);

        return new TimeOffRequestCalendarEvent({
            description: this.description,
            startAt: this.startAt,
            endAt: this.endAt,
            entityColor: this.timeOffPolicy?.timeOffType.color,
            timeOffTypeName: isAccessible ? (this.timeOffPolicy?.timeOffType.name ?? 'Away') : 'Away',
            isPublic: isAccessible,
            status: this.status,
            avatarId: this.employee?.avatarId,
            employeeId: this.employee?.id,
            employeeFullName: this.employee?.fullName,
            firstName: this.employee?.firstName,
            lastName: this.employee?.lastName,
            hasMultipleDays: this.hasMultipleDays,
            totalAmount: this.totalAmount,
            totalAmountInHours: this.totalAmountHours,
            hoursPerDay: this.hoursPerDay,
            displayInHours: this.showHourly,
            type: 'timeOffRequests',
        });
    }

    serializeForRulesVerification(): JsonApiResponse<TimeOffRequestPayload> {
        this.setTimeOffRequestDaysPayload();

        return {
            data: {
                type: TimeOffRequest._type,
                attributes: {
                    id: this.id,
                    employeeId: this.employeeId,
                    timeOffPolicyId: this.timeOffPolicyId,
                    startAt: moment(this.startAt).format(TimeOffDateFormatDashes),
                    endAt: moment(this.endAt).format(TimeOffDateFormatDashes),
                    timeOffRequestDaysPayload: this.timeOffRequestDaysPayload,
                },
            },
        };
    }

    setTimeOffRequestDaysPayload(): void {
        this._attributes.timeOffRequestDaysPayload = this.timeOffRequestDays.map((requestDay) =>
            requestDay.toPayload()
        );
    }

    async syncTimeOffRequestDays(): Promise<void> {
        const [timeOffRequestDays] = await TimeOffRequestDay.where('employeeId', this.employee.id)
            .where('timeOffPolicyId', this.timeOffPolicyId)
            .where('startAt', moment(this.startAt).format(TimeOffDateFormatDashes))
            .where('endAt', moment(this.endAt).format(TimeOffDateFormatDashes))
            .get();

        this.timeOffRequestDays = timeOffRequestDays.map((timeOffRequestDay: TimeOffRequestDay) => {
            timeOffRequestDay.timeOffType = this.timeOffPolicy.timeOffType;

            return timeOffRequestDay;
        });
    }

    save(options: SaveOptions = { includeId: true, onlyDirty: false }): Promise<any> {
        this.setTimeOffRequestDaysPayload();

        return super.save(options);
    }
}

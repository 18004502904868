import { Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Platform } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { Pagination } from '@app/interfaces';
import { BankAccount } from '@app/models/company/bank-account.model';
import { Company } from '@app/models/company/company.model';
import { Employee } from '@app/models/employee/employee.model';
import { Salary } from '@app/models/employee/salary.model';
import { Payroll } from '@app/models/payroll/payroll.model';
import { DocumentStatus, SelfOnboardingDocument } from '@app/models/payroll/self-onboarding-document.model';
import { SelfServeQuickstart } from '@app/models/self-serve/self-serve-quickstart.model';
import { PayrollStates } from '@app/modules/payroll/enums/payroll-state.enum';
import { SelfOnboardingDocumentsService } from '@app/modules/payroll/services';
import {
    PAYROLL_AUTHORIZATION_DOCUMENTS,
    PAYROLL_SUPPORTING_DOCUMENTS,
    SelfOnboardingDocumentType,
} from '@app/modules/payroll/types/self-onboarding-document-type.type';
import { AuthService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { KYCKYBService } from '@app/services/kyc-kyb/kyc-kyb.service';
import * as Sentry from '@sentry/angular';
import { endOfDay, isPast, parse } from 'date-fns';
import addDays from 'date-fns/add_days';
import isAfter from 'date-fns/is_after';
import { isNil } from 'lodash-es';
import { BehaviorSubject, combineLatest, forkJoin, from, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OnboardingStatus } from './types';

/**
 * OnboardingStatusService is used to fetch all the data required to calculate the onboarding status
 * of a company. It also emits a summary of this data so that can components can behave properly.
 *
 * If we end up making too many API calls in this class, we could move the logic to the backend.
 * But for now, this is fine.
 */
@Injectable({
    providedIn: 'root',
})
export class OnboardingStatusService {
    onboardingStatus$: BehaviorSubject<OnboardingStatus | null>;
    isKYBEnabled = false;

    constructor(
        private authService: AuthService,
        private selfOnboardingDocumentsService: SelfOnboardingDocumentsService,
        featureService: FeatureService,
        private knowYourService: KYCKYBService
    ) {
        this.onboardingStatus$ = new BehaviorSubject<OnboardingStatus | null>(null);
        combineLatest([this.authService.onLoginStatusChange, featureService.has(FeatureFlag.KYC_KYB)])
            .pipe(takeUntilDestroyed())
            .subscribe(([isLoggedIn, isKYBEnabled]) => {
                this.isKYBEnabled = isKYBEnabled;
                if (isLoggedIn) {
                    this.refresh();
                    return;
                }

                this.onboardingStatus$.next(null);
            });
    }

    /**
     * refresh is called to refresh the data used by this service
     * and a new onboardingStatus is sent out to subscribers.
     * Call this is when you update important state and want the onboardingStatus
     * to be recalculated.
     */
    async refresh(): Promise<void> {
        // no-op if there isn't a quickstart
        // there will only be a quickstart for self serve companies
        if (!this.authService.company.selfServeQuickstart) {
            return;
        }

        const onboardingStatus = await this.loadCurrentStatus();

        this.onboardingStatus$.next(onboardingStatus);
    }

    /**
     * hasActiveQuickstart is a helper method used to synchronously inform
     * the caller if the quickstart is active.
     *
     * This method depends on the auth service's company, which will
     * have the SelfServeQuickstart.
     *
     * This needs to be done synchronously because otherwise routing will be delayed.
     */
    hasActiveQuickstart(): boolean {
        if (!this.authService.isAdmin()) {
            return false;
        }

        const hasActiveQuickstart =
            this.authService.company.selfServeQuickstart && !this.authService.company.selfServeQuickstart.completedAt;

        return hasActiveQuickstart;
    }

    private async loadCurrentStatus(): Promise<OnboardingStatus> {
        // Load all the data we need to derive the onboarding status.
        // This is 5 api calls, but they happen in parallel.
        // If this ends up being an issue, we can move it to the backend,
        // but I cannot see this being an issue any time soon.
        const [company, employee, [salaries], [, allEmployeeMetaData]]: [
            Company,
            Employee,
            [Salary[], unknown],
            [unknown, { pagination: Pagination }],
        ] = await Promise.all([
            Company.with([
                'selfServeQuickstart',
                'selfServeOnboarding',
                'setupGuides',
                'setupGuides.module',
                'setupGuides.setupGuideSteps',
            ]).find(this.authService.company.id),
            Employee.param('company', this.authService.company.id).with(['account']).find(this.authService.employee.id),
            Salary.param('company', this.authService.company.id)
                .param('employee', this.authService.employee.id)
                .where('current', true)
                .get(),
            Employee.param('company', this.authService.company.id)
                .whereIn('status', ['active', 'terminated', 'onboarding', 'on leave'])
                .limit(1)
                .get(),
        ]);

        const hasSalary = salaries.length > 0;
        const employeeCount = allEmployeeMetaData.pagination.total;

        const onboardingStatus = newOnboardingStatus(this.isKYBEnabled);
        onboardingStatus.quickstartType = company.selfServeOnboarding?.selfServePackage ?? null;

        switch (onboardingStatus.quickstartType) {
            case 'HR_PAYROLL_TIMEOFF':
                this.setPayrollSituation(onboardingStatus);
                await this.setPayYourTeam(onboardingStatus, company, employee, employeeCount, hasSalary);
                this.setBookACall(onboardingStatus, company.selfServeQuickstart);
                this.setTimeOff(onboardingStatus, company);
                await this.setPayrollDocuments(onboardingStatus, company);
                this.setGoalDates(onboardingStatus, company);
                onboardingStatus.totalSteps = 6;
                onboardingStatus.stepsCompleted = [
                    onboardingStatus.payrollSituation.percentageComplete === 100,
                    onboardingStatus.payYourTeam.percentageComplete === 100,
                    onboardingStatus.bookACall.percentageComplete === 100,
                    onboardingStatus.payrollDocuments.isComplete,
                    onboardingStatus.timeOff.percentageComplete === 100,
                    onboardingStatus.runFirstPayroll.isComplete,
                ].filter(Boolean).length;
                break;
            case 'HR_PAYROLL':
                this.setPayrollSituation(onboardingStatus);
                await this.setPayYourTeam(onboardingStatus, company, employee, employeeCount, hasSalary);
                this.setBookACall(onboardingStatus, company.selfServeQuickstart);
                await this.setPayrollDocuments(onboardingStatus, company);
                this.setGoalDates(onboardingStatus, company);
                onboardingStatus.totalSteps = 5;
                onboardingStatus.stepsCompleted = [
                    onboardingStatus.payrollSituation.percentageComplete === 100,
                    onboardingStatus.payYourTeam.percentageComplete === 100,
                    onboardingStatus.bookACall.percentageComplete === 100,
                    onboardingStatus.payrollDocuments.isComplete,
                    onboardingStatus.runFirstPayroll.isComplete,
                ].filter(Boolean).length;
                break;
            case 'HR_TIMEOFF':
                this.setCompanyDetails(onboardingStatus, company);
                this.setAddYourTeam(onboardingStatus, employee, employeeCount, hasSalary);
                this.setTimeOff(onboardingStatus, company);
                onboardingStatus.sendInvitations.isComplete = Boolean(company.selfServeQuickstart.invitationsSentAt);
                onboardingStatus.totalSteps = 4;
                onboardingStatus.stepsCompleted = [
                    onboardingStatus.companyDetails.isComplete,
                    onboardingStatus.addYourTeam.percentageComplete === 100,
                    onboardingStatus.timeOff.percentageComplete === 100,
                    onboardingStatus.sendInvitations.isComplete,
                ].filter(Boolean).length;
                break;
        }

        onboardingStatus.isComplete = Boolean(company.selfServeQuickstart.completedAt);
        onboardingStatus.isWebinarBannerDismissed = Boolean(company.selfServeQuickstart.webinarBannerDismissedAt);

        this.updateSelfServeQuickstartEndDates(onboardingStatus, company.selfServeQuickstart);

        return onboardingStatus;
    }

    /**
     * setPayrollSituation sets everything to complete. It is currently not possible to
     * be in quickstart if this is not complete.
     */
    private setPayrollSituation(onboardingStatus: OnboardingStatus): void {
        onboardingStatus.payrollSituation.percentageComplete = 100;
        onboardingStatus.payrollSituation.steps.tellUs = true;
        onboardingStatus.payrollSituation.steps.timeline = true;
    }

    private async setPayYourTeam(
        onboardingStatus: OnboardingStatus,
        company: Company,
        employee: Employee,
        employeeCount: number,
        hasSalary: boolean
    ): Promise<void> {
        const bankAccount = company.bankAccountId
            ? await BankAccount.param('company', company.id).find(company.bankAccountId)
            : new BankAccount();

        onboardingStatus.payYourTeam.steps.craAccount = Boolean(
            company.name &&
                company.craBusinessNumber &&
                company.craProgramCode &&
                company.craReferenceNumber &&
                company.remitterType
        );

        onboardingStatus.payYourTeam.steps.bankAccount = Boolean(
            bankAccount.institutionNumber && bankAccount.branchNumber && bankAccount.accountNumber
        );

        onboardingStatus.payYourTeam.steps.companyProfile = Boolean(company.primaryAddressId);

        onboardingStatus.payYourTeam.steps.adminProfile = Boolean(
            employee.firstName &&
                employee.lastName &&
                employee.account.legalFirstName &&
                employee.account.legalLastName &&
                employee.hiredAt &&
                employee.bornOn &&
                employee.sin &&
                employee.officeId &&
                employee.bankAccountId &&
                employee.addressId &&
                employee.jobId &&
                hasSalary
        );

        // add your team is done if you have more than one employee, in any status
        onboardingStatus.payYourTeam.steps.addTeam = employeeCount > 1;

        onboardingStatus.payYourTeam.percentageComplete =
            (Object.values(onboardingStatus.payYourTeam.steps).filter(Boolean).length /
                Object.values(onboardingStatus.payYourTeam.steps).length) *
            100;
    }

    private setBookACall(onboardingStatus: OnboardingStatus, selfServeQuickstart: SelfServeQuickstart): void {
        onboardingStatus.bookACall.steps.reviewPlanAndBookCall = Boolean(selfServeQuickstart.cxMeetingAt);
        onboardingStatus.bookACall.cxMeetingAt = selfServeQuickstart.cxMeetingAt;
        onboardingStatus.bookACall.percentageComplete = onboardingStatus.bookACall.steps.reviewPlanAndBookCall
            ? 100
            : 0;
    }

    private async setPayrollDocuments(onboardingStatus: OnboardingStatus, company: Company): Promise<void> {
        // if the company is not yet on payroll, payroll documents cannot have made any progress
        if (!company.isPayrollSyncEnabled) {
            return;
        }

        // if the company has already run a payroll, don't bother checking specifics because this must all be done.
        // this save us networks calls to payroll
        if (company.selfServeQuickstart.firstPayrollRunAt) {
            onboardingStatus.payrollDocuments.steps.authorizationDocuments = true;
            if (!this.isKYBEnabled) {
                onboardingStatus.payrollDocuments.steps.supportingDocuments = true;
            }
            onboardingStatus.payrollDocuments.totalDocumentStatus = 'verified';
            onboardingStatus.payrollDocuments.isComplete = true;
            onboardingStatus.payrollDocuments.percentageComplete = 100;
            onboardingStatus.runFirstPayroll.isComplete = true;

            return;
        }

        onboardingStatus.isCompanyOnPayroll = true;

        const { runPayrolls, authorizationDocuments, supportingDocuments, KYBStatus } = await forkJoin({
            runPayrolls: from(
                Payroll.param('company', company.id)
                    .where('states', [
                        PayrollStates.Paid,
                        PayrollStates.Paying,
                        PayrollStates.Pending,
                        PayrollStates.Processing,
                    ])
                    .limit(1)
                    .get()
            ).pipe(map((response) => response[0])),
            authorizationDocuments: from(
                this.selfOnboardingDocumentsService.getDocuments(PAYROLL_AUTHORIZATION_DOCUMENTS)
            ),
            // Depending on the feature-flag value we either get Supporting Documents OR the KYB status, but never both
            supportingDocuments: this.isKYBEnabled
                ? of(undefined)
                : from(this.selfOnboardingDocumentsService.getDocuments(PAYROLL_SUPPORTING_DOCUMENTS)),
            KYBStatus: this.isKYBEnabled ? this.knowYourService.companyStatus.pipe(take(1)) : of(undefined),
        }).toPromise();

        let authorizationDocumentsComplete = true;
        for (const doc of Object.values(authorizationDocuments)) {
            if (doc.state !== 'verified' && doc.state !== 'ready_for_review') {
                authorizationDocumentsComplete = false;
                break;
            }
        }

        let supportingDocumentsComplete: boolean | undefined = undefined;
        if (supportingDocuments) {
            supportingDocumentsComplete = true;
            for (const doc of Object.values(supportingDocuments)) {
                if (
                    // Weird TS behaviour with Object.values types these as unknown despite most editors properly typing them. Casting is necessary to allow for compilation
                    (doc as SelfOnboardingDocument).state !== 'verified' &&
                    (doc as SelfOnboardingDocument).state !== 'ready_for_review'
                ) {
                    supportingDocumentsComplete = false;
                    break;
                }
            }
        }

        // set documents total status for UI
        let totalDocumentStatus: DocumentStatus | null = null;

        let allDocs: SelfOnboardingDocument<SelfOnboardingDocumentType>[] = Object.values(authorizationDocuments);
        if (supportingDocuments) {
            allDocs = allDocs.concat(Object.values(supportingDocuments));
        }

        let numDocsComplete = 0;
        for (let i = 0; i < allDocs.length; i += 1) {
            switch (allDocs[i].state) {
                case 'rejected':
                    totalDocumentStatus = 'rejected';
                    break;
                case 'verified':
                    numDocsComplete += 1;
                    if (totalDocumentStatus === null) {
                        totalDocumentStatus = 'verified'; // Only change to verified if status hasn't been set
                    }
                    break;
                case 'ready_for_review':
                    numDocsComplete += 1;
                    if (totalDocumentStatus === null || totalDocumentStatus === 'verified') {
                        totalDocumentStatus = 'ready_for_review'; // Only change to ready_for_review if status hasn't been set
                    }
                    break;
                case 'new':
                    if (totalDocumentStatus !== 'rejected') {
                        totalDocumentStatus = 'new'; // Only change to ready_for_review if status hasn't been set
                    }
            }
        }

        // If the KYB flag is enabled then the total document status needs to be reverified with the additional data
        if (!isNil(KYBStatus)) {
            switch (KYBStatus) {
                case 'not_complete':
                    if (totalDocumentStatus !== 'rejected') {
                        totalDocumentStatus = 'new';
                    }
                    break;
                case 'in_progress':
                    numDocsComplete += 1;
                    if (totalDocumentStatus === 'verified') {
                        totalDocumentStatus = 'ready_for_review';
                    }
                    break;
                case 'complete':
                    numDocsComplete += 1;
            }
        }

        onboardingStatus.payrollDocuments.steps.authorizationDocuments = authorizationDocumentsComplete;
        onboardingStatus.payrollDocuments.steps.supportingDocuments = supportingDocumentsComplete;
        onboardingStatus.payrollDocuments.totalDocumentStatus = totalDocumentStatus ?? 'new';
        onboardingStatus.payrollDocuments.isComplete = totalDocumentStatus === 'verified';
        onboardingStatus.payrollDocuments.percentageComplete =
            (numDocsComplete / (allDocs.length + (isNil(KYBStatus) ? 0 : 1))) * 100;

        onboardingStatus.runFirstPayroll.isComplete = runPayrolls.length > 0;

        // async save that payroll has been run so we don't have to re-calculate this again
        if (runPayrolls.length > 0) {
            company.selfServeQuickstart.firstPayrollRunAt = new Date();
            company.selfServeQuickstart.save();
        }
    }

    private setCompanyDetails(onboardingStatus: OnboardingStatus, company: Company): void {
        // these are the fields we are about for company details
        const companyDetailsFields = [
            company.name,
            company.incorporationDate,
            company.phonePrimary,
            company.notification_email,
            company.primaryAddressId,
        ];

        // unlike elsewhere in the quickstart, because company details is a single page,
        // we are calculating percentage done based on fields, not number of pages complete.
        onboardingStatus.companyDetails.percentageComplete =
            (companyDetailsFields.filter(Boolean).length / companyDetailsFields.length) * 100;

        onboardingStatus.companyDetails.steps.addCompanyInfo =
            onboardingStatus.companyDetails.percentageComplete === 100;

        onboardingStatus.companyDetails.isComplete = onboardingStatus.companyDetails.percentageComplete === 100;
    }

    private setAddYourTeam(
        onboardingStatus: OnboardingStatus,
        employee: Employee,
        employeeCount: number,
        hasSalary: boolean
    ): void {
        onboardingStatus.addYourTeam.steps.completeYourProfile = Boolean(
            employee.firstName &&
                employee.lastName &&
                employee.account.legalFirstName &&
                employee.account.legalLastName &&
                employee.addressId &&
                employee.hiredAt &&
                employee.officeId &&
                employee.departmentId &&
                employee.positionId &&
                hasSalary
        );

        // add your team is done if you have more than one employee, in any status
        onboardingStatus.addYourTeam.steps.addEmployees = employeeCount > 1;

        onboardingStatus.addYourTeam.percentageComplete =
            (Object.values(onboardingStatus.addYourTeam.steps).filter(Boolean).length /
                Object.values(onboardingStatus.addYourTeam.steps).length) *
            100;
    }

    private setTimeOff(onboardingStatus: OnboardingStatus, company: Company): void {
        const timeOffSetupGuideSteps = company.setupGuides.find((guide) => {
            return guide.module.name === Platform.modules.timeOff;
        })?.setupGuideSteps;

        if (!timeOffSetupGuideSteps) {
            return;
        }

        const steps = onboardingStatus.timeOff.steps;
        const guideSteps = timeOffSetupGuideSteps;

        steps.setUpWorkSchedules = Boolean(guideSteps.find((s) => s.stepKey === 'setup_work_schedule')?.completedAt);
        steps.setUpTimeOffTypes = Boolean(guideSteps.find((s) => s.stepKey === 'setup_time_off_types')?.completedAt);
        steps.setUpTimeOffPolicies = Boolean(
            guideSteps.find((s) => s.stepKey === 'setup_time_off_policies')?.completedAt
        );
        steps.assignATimeOffPolicy = Boolean(
            guideSteps.find((s) => s.stepKey === 'assign_time_off_policy')?.completedAt
        );
        steps.importTimeOffData = Boolean(guideSteps.find((s) => s.stepKey === 'import_time_off_data')?.completedAt);

        onboardingStatus.timeOff.percentageComplete =
            (Object.values(onboardingStatus.timeOff.steps).filter(Boolean).length /
                Object.values(onboardingStatus.timeOff.steps).length) *
            100;
    }

    /**
     * setGoalDates adds goal dates for each step. We only do this for payroll plans.
     */
    private setGoalDates(onboardingStatus: OnboardingStatus, company: Company): void {
        const goalDate = company.selfServeOnboarding.firstPaydayDate
            ? parse(company.selfServeOnboarding.firstPaydayDate)
            : null;

        const dayBeforeGoalDate = goalDate ? subtractBusinessDays(goalDate, 1) : new Date();

        const trialEnd = company.selfServeQuickstart.trialExpiresAt ?? addDays(company.createdAt, 14);

        onboardingStatus.payrollSituation.targetDate = company.createdAt;
        onboardingStatus.payYourTeam.targetDate = subtractBusinessDays(trialEnd, 7);
        if (goalDate && isAfter(onboardingStatus.payYourTeam.targetDate, goalDate)) {
            onboardingStatus.payYourTeam.targetDate = dayBeforeGoalDate;
        }

        onboardingStatus.payYourTeam.isOverdue =
            onboardingStatus.payYourTeam.percentageComplete !== 100 &&
            isOverdue(onboardingStatus.payYourTeam.targetDate);

        onboardingStatus.bookACall.targetDate = subtractBusinessDays(trialEnd, 6);
        if (goalDate && isAfter(onboardingStatus.bookACall.targetDate, goalDate)) {
            onboardingStatus.bookACall.targetDate = dayBeforeGoalDate;
        }

        onboardingStatus.bookACall.isOverdue =
            onboardingStatus.bookACall.percentageComplete !== 100 && isOverdue(onboardingStatus.bookACall.targetDate);

        onboardingStatus.payrollDocuments.targetDate = subtractBusinessDays(trialEnd, 6);
        if (goalDate && isAfter(onboardingStatus.payrollDocuments.targetDate, goalDate)) {
            onboardingStatus.payrollDocuments.targetDate = dayBeforeGoalDate;
        }
        onboardingStatus.payrollDocuments.isOverdue =
            !onboardingStatus.payrollDocuments.isComplete && isOverdue(onboardingStatus.payrollDocuments.targetDate);

        onboardingStatus.timeOff.targetDate = subtractBusinessDays(trialEnd, 5);
        if (goalDate && isAfter(onboardingStatus.timeOff.targetDate, goalDate)) {
            onboardingStatus.timeOff.targetDate = dayBeforeGoalDate;
        }

        onboardingStatus.timeOff.isOverdue =
            onboardingStatus.timeOff.percentageComplete !== 100 && isOverdue(onboardingStatus.timeOff.targetDate);

        onboardingStatus.runFirstPayroll.targetDate = goalDate ?? trialEnd;
        onboardingStatus.runFirstPayroll.isOverdue =
            !onboardingStatus.runFirstPayroll.isComplete && isOverdue(onboardingStatus.runFirstPayroll.targetDate);
    }

    /**
     * updateSelfServeQuickstartEndDates updates the quickstart end date timestamps based on new completions.
     */
    private async updateSelfServeQuickstartEndDates(
        onboardingStatus: OnboardingStatus,
        quickstart: SelfServeQuickstart
    ): Promise<void> {
        const now = new Date();
        if (onboardingStatus.payYourTeam.percentageComplete === 100 && !quickstart.payYourTeamEndedAt) {
            quickstart.payYourTeamEndedAt = now;
        }

        if (onboardingStatus.bookACall.percentageComplete === 100 && !quickstart.bookACallEndedAt) {
            quickstart.bookACallEndedAt = now;
        }

        if (onboardingStatus.payrollDocuments.isComplete && !quickstart.payrollDocumentsEndedAt) {
            quickstart.payrollDocumentsEndedAt = now;
        }

        if (onboardingStatus.companyDetails.isComplete && !quickstart.companyDetailsEndedAt) {
            quickstart.companyDetailsEndedAt = now;
        }

        if (onboardingStatus.addYourTeam.percentageComplete === 100 && !quickstart.addYourTeamEndedAt) {
            quickstart.addYourTeamEndedAt = now;
        }

        if (onboardingStatus.timeOff.percentageComplete === 100 && !quickstart.timeOffEndedAt) {
            quickstart.timeOffEndedAt = now;
        }

        if (quickstart.isDirty()) {
            try {
                await quickstart.save();
            } catch (error) {
                Sentry.captureException(error);
            }
        }
    }
}

/**
 * newOnboardingStatus creates a new, default/empty state status
 * This is used to quickly generate a status for further modification.
 */

export function newOnboardingStatus(isKYBEnabled = false): OnboardingStatus {
    return {
        payrollSituation: {
            percentageComplete: 0,
            targetDate: new Date(),
            steps: {
                tellUs: false,
                timeline: false,
            },
        },
        payYourTeam: {
            percentageComplete: 0,
            targetDate: new Date(),
            isOverdue: false,
            steps: {
                craAccount: false,
                bankAccount: false,
                companyProfile: false,
                adminProfile: false,
                addTeam: false,
            },
        },
        bookACall: {
            percentageComplete: 0,
            isOverdue: false,
            targetDate: new Date(),
            cxMeetingAt: null,
            steps: {
                reviewPlanAndBookCall: false,
            },
        },
        payrollDocuments: {
            /**
             * complete is the total completion of this step.
             * it is possible for payrollDocuments to be incomplete while percentageComplete is 100.
             * percentageComplete measures user completion, but full completion requires cx work.
             */
            isComplete: false,
            percentageComplete: 0,
            isOverdue: false,
            targetDate: new Date(),
            totalDocumentStatus: 'new',
            steps: {
                authorizationDocuments: false,
                supportingDocuments: isKYBEnabled ? undefined : false,
            },
        },
        timeOff: {
            percentageComplete: 0,
            targetDate: new Date(),
            isOverdue: false,
            steps: {
                setUpWorkSchedules: false,
                setUpTimeOffTypes: false,
                setUpTimeOffPolicies: false,
                assignATimeOffPolicy: false,
                importTimeOffData: false,
            },
        },
        runFirstPayroll: {
            targetDate: new Date(),
            isOverdue: false,
            isComplete: false,
        },
        companyDetails: {
            isComplete: false,
            percentageComplete: 0,
            steps: {
                addCompanyInfo: false,
            },
        },
        addYourTeam: {
            percentageComplete: 0,
            steps: {
                completeYourProfile: false,
                addEmployees: false,
            },
        },
        sendInvitations: { isComplete: false },
        quickstartType: null,
        isComplete: false,
        isWebinarBannerDismissed: false,
        isCompanyOnPayroll: false,
        stepsCompleted: 0,
        totalSteps: 0,
    };
}

/**
 * subtractBusinessDays is a very rough, very quick implementation
 * It doesn't handle holidays, and it goes through every single date
 * (if you pass it 500, it will iterate > 500 times)
 * Don't use this outside this class.
 */
function subtractBusinessDays(date: Date, numDays: number): Date {
    const subtractedDate = new Date(date);

    while (numDays > 0) {
        subtractedDate.setDate(subtractedDate.getDate() - 1);

        // handle Sunday and Saturday
        if (subtractedDate.getDay() !== 0 && subtractedDate.getDay() !== 6) {
            numDays -= 1;
        }
    }

    return subtractedDate;
}

/**
 * isOverdue decides if a given date is overdue. We use this
 * to display a warning.
 * If the end of the given date has passed, it's for sure overdue.
 */
function isOverdue(date: Date): boolean {
    return isPast(endOfDay(date));
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Company } from '@app/models/company/company.model';
import { AuthService, FileHelperService, TokenService, UserAccessService } from '@app/services';
import { TokenRole } from '@app/services/token.service';

@Component({
    selector: 'app-company-selector',
    templateUrl: './company-selector.template.html',
    styleUrls: ['./company-selector.style.scss'],
})
export class CompanySelectorComponent {
    @Output() toggleMobileCompanyDrawer: EventEmitter<void> = new EventEmitter<void>();
    @Input() hideName: 'always' | 'never' | 'mobile' = 'mobile';

    uniqueCompanyTokenRoles: TokenRole[] = this.tokenService.uniqueCompanyTokenRoles();
    currentTokenRole: TokenRole = this.tokenService.activeToken();
    company: Company | null = this.auth.company;
    hasMultipleCompanies = this.tokenService.hasMultipleCompanies();

    constructor(
        private auth: AuthService,
        private userAccess: UserAccessService,
        private tokenService: TokenService,
        private fileHelper: FileHelperService
    ) {}

    getCompanyNameVisibility = (): string => ({ mobile: 'hide-small', always: 'd-none', never: '' })[this.hideName];

    onToggleMobileCompanyDrawer(): void {
        this.toggleMobileCompanyDrawer.emit();
    }

    getBrandLogoUrl(fileId: number): string {
        return this.fileHelper.serve(fileId);
    }

    changeRole(roleId: number): void {
        this.userAccess.changeRole(roleId);
    }
}

import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { HideIfFeatureFlagTrueDirective } from '@app/directives/feature-flag/hide-if-feature-flag';
import { ShowIfFeatureFlagTrueDirective } from '@app/directives/feature-flag/show-if-feature-flag';
import { TranslatableKey } from '@app/types/translatable.type';
import '@humi-design-library/components/HumiLogo';
import { HumiLogoLabelOrientation, HumiLogoVariant } from '@humi-design-library/components/HumiLogo';
import { TranslateModule } from '@ngx-translate/core';

export type Sizes = 'tiny' | 'small' | 'medium' | 'large' | 'huge';
/**
 * HumiLogoComponent is a component that shows the Humi logo.
 */
@Component({
    imports: [HideIfFeatureFlagTrueDirective, ShowIfFeatureFlagTrueDirective, TranslateModule],
    selector: 'app-humi-logo',
    standalone: true,
    styleUrls: ['./humi-logo.component.scss'],
    templateUrl: './humi-logo.component.html',
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WebHumiLogoComponent {
    @Input() size: Sizes = 'medium';

    @Input() variant: HumiLogoVariant = 'color-auto';

    @Input() labelOrientation?: HumiLogoLabelOrientation;

    @Input() ariaLabel: TranslatableKey = 'screen-reader-only.humiLogo';

    /**
     * Temporarily needed prior to the Humi Rebranding to allow us to still serve the existing logo. Can be deleted after launch
     */
    get legacyVanillaLogoPath(): string {
        if (this.labelOrientation === 'vertical') {
            return '';
        }

        if (this.labelOrientation === 'horizontal') {
            if (this.variant.includes('color')) {
                return '/assets/humi-logos/color.svg';
            } else if (this.variant.includes('white')) {
                return '/assets/humi-logos/white.svg';
            } else {
                return '/assets/humi-logos/symbol-black.svg';
            }
        }

        if (this.variant.includes('color')) {
            return '/assets/humi-logos/symbol-color.svg';
        } else if (this.variant.includes('white')) {
            return '/assets/humi-logos/symbol-white.svg';
        } else {
            return '/assets/humi-logos/symbol-black.svg';
        }
    }
}

export enum SocketEventTypes {
    documentDownloaded = '.document.downloaded',
    signingTemplateReady = '.signingTemplate.ready',
    notifications = '.notifications',
    policyAssignmentProgressed = '.policyAssignment.progressed',
    asyncSystemTaskCreated = '.async-task.created',
    asyncSystemTaskCompleted = '.async-task.completed',
    asyncSystemTaskFailed = '.async-task.failed',
    jobPostedOnIndeed = '.job.posted',
    jobPostingFailedOnIndeed = '.job.posting.failed',
    newTaskTemplateCreated = '.task-template.created',
    payrollRunCompleted = '.payroll-run.completed',
    payrollRunProgress = '.payroll-run.progress',
    payrollRunError = '.payroll-run.error',
    payrollPayProgress = '.payroll-pay.progress',
    payrollPayCompleted = '.payroll-pay.completed',
    payrollPayError = '.payroll-pay.error',
    afterPayrollPaidProgress = '.after-payroll-paid.progress',
    afterPayrollPaidCompleted = '.after-payroll-paid.completed',
    afterPayrollPaidError = '.after-payroll-paid.error',
    payrollRunHoursProgress = '.payroll-run-hours.progress',
    payrollRunHoursCompleted = '.payroll-run-hours.completed',
    payrollRunHoursError = '.payroll-run-hours.error',
    benefitsInvoiceSyncUpdate = '.benefitsInvoice.syncUpdate',
}

export const ASYNC_TASK_EVENT_TYPES = [
    SocketEventTypes.asyncSystemTaskCreated,
    SocketEventTypes.asyncSystemTaskCompleted,
    SocketEventTypes.asyncSystemTaskFailed,
];

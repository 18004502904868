<div class="year-calendar-container">
    <div class="year-calendar-header">
        <div class="year-calendar-title">
            <h2>{{ title | appTranslate }}</h2>
        </div>
        <mat-form-field>
            <mat-select [ngModel]="year" (selectionChange)="onSwitchYear($event.value)">
                <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
    <div *ngIf="legend.length" class="legend">
        <div *ngFor="let item of legend" class="legend-item">
            <div [style.backgroundColor]="item.color || 'grey'" class="legend-indicator"></div>
            <div>{{ item.title }}</div>
        </div>
    </div>
    <div class="year-calendar">
        <div class="calendar-and-days">
            <div class="days-of-the-week notranslate">
                <div *ngFor="let dayOfTheWeek of daysOfTheWeek" class="day-of-the-week">
                    <span class="hide-small">{{
                        (dayOfTheWeek.desktop ? 'time-off-v3.yearCalendar.' + dayOfTheWeek.desktop : '') | appTranslate
                    }}</span>
                    <span class="only-small">{{
                        'time-off-v3.yearCalendar.' + dayOfTheWeek.mobile | appTranslate
                    }}</span>
                </div>
            </div>
            <div class="year" title="">
                <div
                    *ngFor="let day of days"
                    class="day"
                    title=""
                    [class.is-weekend]="day.isWeekend"
                    [class.is-holiday]="day.isHoliday"
                    [class.has-event]="day.hasEvent"
                    [class.is-out-of-year]="day.isOutOfYear"
                    [class.is-today]="day.isToday"
                    [style.backgroundColor]="day.hasEvent ? day.event.entityColor : ''"
                >
                    <div *ngIf="(day.isWeekend || day.holiday) && day.hasEvent" class="fader"></div>
                    <div class="hover-box"></div>
                    <div class="tool-tip">
                        <div>{{ day.date | appDate: 'EEE MMM d, yyyy' }}</div>
                        <div *ngIf="day.hasEvent && getEventDescription(day.event) as description">
                            <ng-container
                                [ngTemplateOutlet]="eventDescriptionTemplate"
                                [ngTemplateOutletContext]="{ event: day.event, description: description }"
                            />
                        </div>
                        <div *ngIf="day.hasEvent">
                            {{
                                (day.isToday
                                    ? 'components.year-calendar-v3.today'
                                    : day.event.monthCalendarTooltipDescription
                                ) | appTranslate
                            }}
                        </div>
                        <div *ngIf="day.hasEvent && day.event.description">
                            {{
                                {
                                    key: 'components.year-calendar-v3.reasonWithDescription',
                                    params: { description: day.event.description },
                                } | appTranslate
                            }}
                        </div>
                        <div *ngIf="day.isHoliday">({{ day.holiday.summary | appTranslate }})</div>

                        <ng-template #eventDescriptionTemplate let-event="event" let-description="description">
                            @if (event.isSameDayEvent) {
                                {{
                                    event.upcomingEventDescription
                                        | translate
                                            : {
                                                  timeOffTypeName: event.payload.timeOffTypeName,
                                                  value: (event.payload.displayInHours
                                                      ? event.payload.totalAmountInHours
                                                      : event.payload.totalAmount
                                                  )?.toFixed(2),
                                                  unit: event.payload.displayInHours
                                                      ? (event.getPluralOrSingularHourlyUnit | translate)
                                                      : (event.getPluralOrSingularDayUnit | translate),
                                              }
                                }}
                            } @else {
                                <!-- Non-Same-Day Event -->
                                {{
                                    event.dateDescription
                                        | translate
                                            : {
                                                  startAt: description?.startAt,
                                                  endAt: description?.endAt,
                                              }
                                }}
                            }
                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
        <div class="months">
            <div *ngFor="let month of months" class="month">{{ month | appTranslate }}</div>
        </div>
    </div>
</div>

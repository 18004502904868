import { Model } from '@models/core/base.model';

enum FrequencyNames {
    EachPeriod = 'each_period',
    FirstPeriod = 'first_period_of_the_month',
    LastPeriod = 'last_period_of_the_month',
    SecondPeriod = 'second_period_of_the_month',
    OneTime = 'one_time',
}

export class BenefitDeductionFrequency extends Model {
    static SORT_ORDER = [
        FrequencyNames.FirstPeriod,
        FrequencyNames.SecondPeriod,
        FrequencyNames.LastPeriod,
        FrequencyNames.EachPeriod,
        FrequencyNames.OneTime,
    ] as const;

    protected static _resource = 'payroll/benefitDeductionFrequencies';

    protected static _version = 'v2';

    get uuid(): string {
        return this._attributes['uuid'];
    }

    get name(): string {
        return this._attributes['name'];
    }

    get displayName(): string {
        return this._attributes['displayName'];
    }
}

import { SimpleEntityActions } from '@app/classes/simple-entity-actions.class';
import { BenefitDivision } from '@app/models/benefits/benefit-division.model';
import moment from 'moment';
import { BenefitPlan as CompanyBenefitPlan } from '../../benefits/benefit-plan.model';
import { Model } from '../../core/base.model';
import { Employee } from '../employee.model';

export class BenefitPlan extends Model {
    protected static _datetimes = ['createdAt', 'updatedAt', 'deletedAt'];

    protected static _dates = ['divisionEffectiveAt', 'overrideEffectiveAt'];

    get employee(): Employee {
        return this.hasOne(Employee, 'employee');
    }

    get employeeId(): number {
        return this._attributes['employeeId'];
    }
    set employeeId(val: number) {
        this._attributes['employeeId'] = val;
    }
    get benefitPlanId(): number {
        return this._attributes['benefitPlanId'];
    }
    set benefitPlanId(val: number) {
        this._attributes['benefitPlanId'] = val;
    }

    get benefitDivisionId(): number {
        return this._attributes['benefitDivisionId'];
    }
    set benefitDivisionId(val: number) {
        this._attributes['benefitDivisionId'] = val;
    }

    get isEligible(): boolean {
        return this._attributes['isEligible'];
    }
    set isEligible(val: boolean) {
        this._attributes['isEligible'] = val;
    }

    get isEnrolled(): boolean {
        return this._attributes['isEnrolled'];
    }
    set isEnrolled(val: boolean) {
        this._attributes['isEnrolled'] = val;
    }

    get isWaitingPeriodWaved(): boolean {
        return this._attributes['isWaitingPeriodWaved'];
    }
    set isWaitingPeriodWaved(val: boolean) {
        this._attributes['isWaitingPeriodWaved'] = val;
    }

    get isFamily(): boolean {
        return this._attributes['isFamily'];
    }
    set isFamily(val: boolean) {
        this._attributes['isFamily'] = val;
    }

    get cardTitlePrimary(): string {
        return this._attributes['cardTitlePrimary'];
    }
    set cardTitlePrimary(val: string) {
        this._attributes['cardTitlePrimary'] = val;
    }

    get cardValuePrimary(): string {
        return this._attributes['cardValuePrimary'];
    }
    set cardValuePrimary(val: string) {
        this._attributes['cardValuePrimary'] = val;
    }

    get cardTitleSecondary(): string {
        return this._attributes['cardTitleSecondary'];
    }
    set cardTitleSecondary(val: string) {
        this._attributes['cardTitleSecondary'] = val;
    }

    get cardValueSecondary(): string {
        return this._attributes['cardValueSecondary'];
    }
    set cardValueSecondary(val: string) {
        this._attributes['cardValueSecondary'] = val;
    }

    get cardTitleTertiary(): string {
        return this._attributes['cardTitleTertiary'];
    }
    set cardTitleTertiary(val: string) {
        this._attributes['cardTitleTertiary'] = val;
    }

    get cardValueTertiary(): string {
        return this._attributes['cardValueTertiary'];
    }
    set cardValueTertiary(val: string) {
        this._attributes['cardValueTertiary'] = val;
    }

    get benefitPlan(): CompanyBenefitPlan {
        return this.hasOne(CompanyBenefitPlan, 'benefitPlan');
    }

    set benefitPlan(val: CompanyBenefitPlan) {
        this.setOne('benefitPlan', val, 'benefitPlanId');
    }

    get benefitDivision(): BenefitDivision {
        return this.hasOne(BenefitDivision, 'benefitDivision');
    }

    set benefitDivision(val: BenefitDivision) {
        this.setOne('benefitDivision', val, 'benefitDivisionId');
    }

    get effectiveAt(): Date {
        return this._attributes['effectiveAt'];
    }

    set effectiveAt(val: Date) {
        this._attributes['effectiveAt'] = val;
    }

    get divisionEffectiveAt(): Date {
        return this._attributes['divisionEffectiveAt'];
    }

    set divisionEffectiveAt(val: Date) {
        this._attributes['divisionEffectiveAt'] = val;
    }

    get dueAt(): Date {
        return this._attributes['dueAt'];
    }

    get certificateNumber(): number {
        return this._attributes['certificateNumber'];
    }

    set certificateNumber(val: string) {
        this._attributes['certificateNumber'] = val;
    }

    get overrideEffectiveAt(): Date {
        return this._attributes['overrideEffectiveAt'];
    }

    getEnrollmentFormDueDaysLeft(): number {
        const currentDate = moment().startOf('day');
        return Math.floor(moment.duration(moment(this.dueAt).diff(currentDate)).asDays());
    }

    protected static _resource = 'benefitManagement/employees/:employee/benefitPlans';

    protected static _type = 'employeeBenefitPlans';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'employeeId',
        'benefitPlanId',
        'benefitDivisionId',
        'isEligible',
        'isEnrolled',
        'isWaitingPeriodWaved',
        'isFamily',
        'cardTitlePrimary',
        'cardValuePrimary',
        'cardTitleSecondary',
        'cardValueSecondary',
        'cardTitleTertiary',
        'cardValueTertiary',
        'divisionEffectiveAt',
        'dueAt',
        'certificateNumber',
    ];

    static permission = new SimpleEntityActions('benefitPlans');

    shouldShowCard(): boolean {
        return (
            !!this.cardTitlePrimary ||
            !!this.cardTitleSecondary ||
            !!this.cardTitleTertiary ||
            !!this.cardValuePrimary ||
            !!this.cardValueSecondary ||
            !!this.cardValueTertiary
        );
    }
}

import { BasicColor, Color, Shade } from './types';

/**
 * Gets the computed color value from the CSS in its base form (typically HSL)
 * @param color The color string (ie. "primary")
 * @param shade The shade value (ie. 400) NOTE: this is not required on basic colors (white, black)
 * @returns The computed value string (ie. "hsl(280, 30%, 44%)")
 */
export const getColorValue =
    (rootStyle: CSSStyleDeclaration) =>
    (color: Color | BasicColor, shade?: Shade): string => {
        return rootStyle.getPropertyValue(`--${color}` + (shade ? `-${shade}` : ''));
    };

/**
 * Gets the computed color value from the CSS in a hex code. This is sometimes required by 3rd party tools (HelloSign)
 * @param color The color string (ie. "primary")
 * @param shade The shade value (ie. 400) NOTE: this is not required on basic colors (white, black)
 * @returns The computed value string (ie. "#7b4f92")
 */
export const getHexColorValue =
    (rootStyle: CSSStyleDeclaration) =>
    (color: Color | BasicColor, shade?: Shade): string => {
        return shade
            ? hslToHex(getColorValue(rootStyle)(color as Color, shade))
            : hslToHex(getColorValue(rootStyle)(color as BasicColor));
    };

/**
 * Gets the the value of any CSS variable defined in the root style sheet, this can be useful when we want a non-primitive color definition
 * @param variable The variable name beginning with -- (ie. "--card-background-color")
 * @returns The computed value string (ie. "hsl(0, 0%, 20%)")
 */
export const getCSSVariable =
    (rootStyle: CSSStyleDeclaration) =>
    (variable: `--${string}`): string => {
        return rootStyle.getPropertyValue(variable);
    };

/**
 * Converts an HSL string to Hex
 * @param hsl (ie. "hsl(280, 30%, 44%)")
 * @returns The computed hex value string (ie. "#7b4f92")
 */
export const hslToHex = (hsl: string): string => {
    // Parse the Hue, Saturation, and Lightness from the string
    const HSLNumbers = /hsl\((\d{1,3}), (\d{1,3})%, (\d{1,3})%\)/.exec(hsl)?.map((string) => Number(string)) ?? [];
    const [, h, s] = HSLNumbers;
    let l = HSLNumbers[3];
    // Based off of https://stackoverflow.com/a/44134328
    l /= 100;
    const a = (s * Math.min(l, 1 - l)) / 100;
    const f = (n: number): string => {
        const k = (n + h / 30) % 12;
        const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
        return Math.round(255 * color)
            .toString(16)
            .padStart(2, '0');
    };
    return `#${f(0)}${f(8)}${f(4)}`;
};

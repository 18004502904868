import { Directive, ElementRef } from '@angular/core';
import { css } from 'emotion';

const linkStyle = css`
    font-weight: 500;
    color: var(--primary-600);
    transition: 0.2s;
    border-bottom: 1px solid transparent;

    &:hover,
    &:focus {
        color: var(--primary-600);
        border-color: var(--primary-600);
    }

    &:active {
        color: var(--primary-700);
        border-color: var(--primary-700);
    }
`;

@Directive({
    selector: '[uiLink]',
})
export class LinkDirective {
    constructor(el: ElementRef<HTMLAnchorElement>) {
        el.nativeElement.classList.add(linkStyle);
    }
}

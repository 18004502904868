import { Injectable } from '@angular/core';
import {
    applyUserTheme,
    getEvaluatedTheme,
    getThemePreference,
    HUMI_THEME_KEY,
    ThemePreference,
} from '@app/scripts/theme/theme';

@Injectable({ providedIn: 'root' })
export class ThemeService {
    /**
     * Used to cache the themePreference in memory so that we are not repeatedly accessing LocalStorage
     */
    private _themePreference: ThemePreference = 'LIGHT';

    constructor() {
        this.fetchThemePreference();
    }

    get themePreference(): ThemePreference {
        return this._themePreference;
    }

    getEvaluatedTheme(): Omit<ThemePreference, 'MATCH_BROWSER'> {
        return getEvaluatedTheme();
    }

    setThemePreference(themePreference: ThemePreference): void {
        localStorage.setItem(HUMI_THEME_KEY, themePreference);
        applyUserTheme();
        this.fetchThemePreference();
    }

    /**
     * Caches current theme preference from LocalStorage into provide variables
     */
    private fetchThemePreference(): void {
        this._themePreference = getThemePreference();
    }
}

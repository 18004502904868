import { HttpClient, HttpContext, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { DestroyRef, Injectable } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { AppResources } from '@app/app.resources';
import { Platform, PlatformModules } from '@app/classes/platform.class';
import { AUTH_ERROR } from '@app/components/auth0/auth0.view';
import { FORM_PERSISTENCE_PREFIX } from '@app/directives/form-persistence.directive';
import { JsonApiError } from '@app/errors/json-api.error';
import { SKIP_AUTH0_TOKEN, SKIP_HUMI_TOKEN } from '@app/interceptors/context-tokens';
import { Credentials } from '@app/interfaces/credentials.interface';
import { JsonApiResponse } from '@app/interfaces/json-api-resource.interface';
import { AnalyticService } from '@app/services/analytic.service';
import { AppState, AuthService as Auth0, GetTokenSilentlyOptions } from '@auth0/auth0-angular';
import { environment } from '@env/environment';
import { Account } from '@models/account/account.model';
import { Role } from '@models/account/role.model';
import { Company } from '@models/company/company.model';
import { Model } from '@models/core/base.model';
import { Employee } from '@models/employee/employee.model';
import { Permission } from '@models/permissions/permission.model';
import * as Sentry from '@sentry/browser';
import { deserialize } from 'jsonapi-deserializer';
import { Error as JsonApiSerializerError, Serializer } from 'jsonapi-serializer';
import { isNil } from 'lodash-es';
import { BehaviorSubject, Observable, Subject, from, of } from 'rxjs';
import { catchError, ignoreElements, map, switchMap, take, takeWhile, tap, timeout } from 'rxjs/operators';
import { TokenRole, TokenService } from './token.service';

declare let localStorage: Storage;

/**
 * Max number of times we'll redirect back to Auth0 to retry login before giving up
 */
const MAX_RETRY_FAILED_LOGIN_ATTEMPTS = 2;
/**
 * By default the Auth0 service will attempt to communicate with Auth0 for up to 60s before giving up.
 * This is a poor user experience since there screen will just appear to be infinitely loading.
 * 5 seconds should be more than enough time to communicate with Auth0, but this can be upped if need-be
 */
const AUTH0_TIMEOUT_IN_SECONDS = 5;

interface LoginFlags {
    hasCompletedOnboarding: boolean;
    companySubscriptionCreated: boolean;
}

/**
 * When calling loginWithRedirect from the Auth0 service any state can be passed through to Auth0 which is then returned to the app after a successful login
 * We can utilize this to persist state despite being redirected away from the Angular app.
 *
 * This type captures any state we want to persist between Auth0 and Humi.
 * Currently it is used for failed login attempts, but can be expanded to include more attributes.
 */
type Auth0AppState = AppState & { failedLogin?: { attempts: number; message?: string } };

export type AuthenticationResponseData = Record<string, unknown> & { data?: { id?: number } };

/**
 * Creates a definition of how different authentication methods work in our app.
 * This is a read-only constant as it is not to be modified
 *
 * url: The URL to hit in our BE in order to perform authentication
 * body: the definition for how the body is built, IF a body is required for the request
 */
const AUTH_CONFIGS = {
    AUTH0: {
        url: AppResources.Auth0HumiToken,
        body: {
            type: 'validateAuth0Token',
            serializer: new Serializer('validateAuth0Token', {
                keyForAttribute: 'camelCase',
                attributes: ['accessToken', 'impersonation'],
            }),
        },
        requireAuth0Token: false,
    },
    CREDENTIALS: {
        url: AppResources.Authenticate,
        body: {
            type: 'LoginRequest',
            serializer: new Serializer('LoginRequest', {
                keyForAttribute: 'camelCase',
                attributes: ['email', 'password'],
            }),
        },
        requireAuth0Token: false,
    },
    SSO: { url: AppResources.ExchangeSsoToken + '?token=:tokenId', body: null, requireAuth0Token: false },
    IMPERSONATE: {
        url: AppResources.ExchangeImpersonateToken + '?token=:tokenId',
        body: null,
        requireAuth0Token: true,
    },
    'NEW-ACCOUNT': {
        url: AppResources.ExchangeNewAccountToken + '?token=:tokenId',
        body: null,
        requireAuth0Token: false,
    },
} as const;

const STORE_PASSWORD_REQUEST_SERIALIZER: Serializer = new Serializer('passwords', {
    keyForAttribute: 'camelCase',
    attributes: ['value', 'valueConfirmation', 'accountId'],
});

const RESET_PASSWORD_REQUEST_SERIALIZER: Serializer = new Serializer('accounts', {
    keyForAttribute: 'camelCase',
    attributes: ['email'],
});

export type AuthType = keyof typeof AUTH_CONFIGS;

@Injectable()
export class AuthService {
    get activationToken(): string | null {
        return this._activationToken;
    }

    set activationToken(token: string | null) {
        this._activationToken = token;
    }

    /**
     * Authenticated Account Id
     */
    get accountId(): number {
        return this.token.accountId();
    }

    get employee(): Employee {
        if (!this._employee) {
            throw new Error('Employee not set');
        }

        return this._employee;
    }

    get hasDirectReports(): boolean {
        return this._accessibleEmployeeIds.length > 1;
    }
    /**
     * @deprecated Use employee instead
     */
    get user(): Employee | null {
        return this._employee;
    }

    get accessibleEmployeeIds(): number[] {
        return this._accessibleEmployeeIds;
    }

    get flags(): LoginFlags | null {
        return this._flags;
    }

    get role(): Role {
        if (!this._role) {
            throw new Error('Role not set');
        }

        return this._role;
    }

    get account(): Account {
        if (!this._account) {
            throw new Error('Account not set');
        }

        return this._account;
    }

    get company(): Company {
        if (!this._company) {
            throw new Error('Company not set');
        }

        return this._company;
    }

    get redirectUrl(): string | null {
        return localStorage.getItem('redirectUrl');
    }

    set redirectUrl(url: string | null) {
        if (url) {
            localStorage.setItem('redirectUrl', url);
        } else {
            localStorage.removeItem('redirectUrl');
        }
    }

    get authenticationMethod(): AuthType | 'KEEP-ALIVE' | null {
        // This functionality ONLY executes in e2e and local environments where we allow local storage to persist session
        // This ensures we don't waste time polling Auth0 if not necessary
        if (environment.localStorageTokens?.authenticationMethod) {
            return environment.localStorageTokens.authenticationMethod;
        }
        return this._authenticationMethod;
    }

    /**
     * Environments that don't utilize Auth0 (local and E2E) do not have a domain configured to reach out to Auth0.
     * We utilize this to determine logic for whether we should be sending API requests to Auth0 and redirecting to them or the vanilla login screen.
     */
    get isAuth0AvailableInCurrentEnvironment(): boolean {
        return !isNil(environment.auth0?.domain);
    }

    /**
     * beforeLogout fires immediately before the logout actions are called.
     * This is useful if you need to update the UI and can't wait for API calls to complete.
     */
    beforeLogout: Subject<null> = new Subject<null>();
    onLogout: Subject<null> = new Subject<null>();
    onLogin: Subject<null> = new Subject<null>();
    onLoginStatusChange = new BehaviorSubject(false);
    onHydrate: Subject<null> = new Subject();
    authenticationError: string | undefined;

    private isGettingAuth0Token$ = new BehaviorSubject(false);
    private isGettingHumiTokens$ = new BehaviorSubject(false);

    // Global authenticated/hydrated flag
    private _authenticationMethod: AuthType | 'KEEP-ALIVE' | null = null;
    private _hydrated = false;

    private _activationToken: string | null = null;

    private _employee: Employee | null = null;

    private _accessibleEmployeeIds: number[] = [];

    /**
     * A record of which modules are enabled for the company. It is keyed by the module name for easy access
     * ie. {  Payroll: true }
     */
    private _modules = this.mapCompanyModules(Object.values(Platform.modules), false);

    /**
     * Authentication Flags
     */
    private _flags: any = {};

    /**
     * Authenticated Role
     */
    private _role: Role | null = null;

    /**
     * Authenticated Account
     */
    private _account: Account | null = null;

    /**
     * Authenticated Company
     */
    private _company: Company | null = null;

    /**
     * Humi's record of the AppState being returned from Auth0
     */
    private _auth0AppState: Auth0AppState = { failedLogin: { attempts: 0 } };

    constructor(
        private http: HttpClient,
        private token: TokenService,
        private router: Router,
        private analyticService: AnalyticService,
        private auth0: Auth0,
        private destroyRef: DestroyRef
    ) {
        this.subscribeToAuth0State();
    }

    isSelf(employee: Employee | number): boolean {
        if (!this._employee) {
            return false;
        }

        if (typeof employee === 'number') {
            return this._employee.id === employee;
        }

        return this._employee.id === employee.id;
    }

    supervises(employee: Employee | number): boolean {
        if (this.isSelf(employee)) {
            return false;
        }
        if (typeof employee === 'number') {
            return this._accessibleEmployeeIds.includes(employee);
        }
        return this._accessibleEmployeeIds.includes(employee.id);
    }

    /**
     * Check a specific permissions exists on the Role model permission relation
     */
    can(permission: string | string[]): boolean {
        if (Array.isArray(permission)) {
            return this.canAll(permission);
        }
        return this.canOne(permission);
    }

    /**
     * Checks if the user has the administrate permission
     * This does *not* mean they are for sure a default admin.
     * They could be a custom role admin
     */
    isAdmin(): boolean {
        return this.can(Platform.permission.administrate);
    }

    /**
     * Whether the user has the ability to run payroll. This checks three things:
     * 1. Do they have the accessPayroll permission
     * 2. Is the isPayrollSyncEnabled flag set to "true" for the company
     * 3. Do they have the payroll module
     */
    get hasPayrollAccess(): boolean {
        return (
            this.can(Platform.payroll.permission.view) &&
            this.hydrated && // We must check that they are hydrated first since we cannot access the company prior to hydration
            this.company.isPayrollSyncEnabled &&
            this.companyHasModule(Platform.modules.payroll)
        );
    }

    /**
     * isAssignedTimeTrackingAdmin checks if the currently user
     * is an admin who can "access" time tracking and is assigned to time tracking.
     */
    isAssignedTimeTrackingAdmin(): boolean {
        return (
            this.can(Platform.timeTracking.permission.view) &&
            Boolean(this._employee?.hasModule(Platform.modules.timeTracking))
        );
    }

    /**
     * Checks if the user is a default admin at the company
     */
    isDefaultAdmin(): boolean {
        return this.can(Platform.permission.administrate) && this.role.isImmutable;
    }

    /**
     * Like can, but backwards
     */
    cant(permission: string | string[]): boolean {
        return !this.can(permission);
    }

    /**
     * @deprecated
     * Check a specific model against the access level and permissions of an employee
     */
    canView(model: Model, key: string, checkAccount = false): boolean {
        if (!model) {
            return false;
        }

        // This gets the static property on the model's class
        const permission = Object.getPrototypeOf(model).constructor.permission;

        if (!this.canAll(permission.view)) {
            return false;
        }

        return this.hasManageAccess(model, key, checkAccount);
    }

    /**
     * @deprecated
     * Check a specific model against the access level and permissions of an employee
     */
    canEdit(model: Model, key = 'id', checkAccount = false): boolean {
        if (!model) {
            return false;
        }

        // This gets the static property on the model's class
        const permission = Object.getPrototypeOf(model).constructor.permission;

        if (!this.canAll(permission.edit)) {
            return false;
        }

        return this.hasManageAccess(model, key, checkAccount);
    }

    /**
     * @deprecated
     */
    canAccountEdit(model: Model, key: string, checkAccount = true): boolean {
        return this.canEdit(model, key, checkAccount);
    }

    redirectToLogin(appState?: Auth0AppState): Promise<void> {
        if (this.isAuth0AvailableInCurrentEnvironment) {
            return this.auth0.loginWithRedirect({ appState }).toPromise();
        }
        return this.router.navigate(['/login']).then(() => void 0);
    }

    retrieveAllTokens(auth0Options?: GetTokenSilentlyOptions): Observable<void> {
        return this.retrieveAuth0Token(auth0Options).pipe(
            switchMap(() => this.retrieveHumiTokens()),
            map(() => void 0), // Always return void since tokens are stored in token service
            catchError((error) => {
                this.isGettingAuth0Token$.next(false);
                this.isGettingHumiTokens$.next(false);
                throw error;
            })
        );
    }

    /**
     * Silently attempts to retrieve an authentication token from Auth0
     * Returns void as the token will be stored in the token service
     */
    retrieveAuth0Token(options?: GetTokenSilentlyOptions): Observable<void> {
        // On local environments where there is no Auth0, simply return
        if (!this.isAuth0AvailableInCurrentEnvironment) {
            return of(void 0);
        }

        // If a different request has already started to retrieve the token from Auth0 we wait for them to finish rather than sending a duplicate request
        if (this.isGettingAuth0Token$.value) {
            return this.waitForToken('AUTH0');
        }

        // Observable used to tell other requests to wait while we retrieve the token
        this.isGettingAuth0Token$.next(true);
        return this.auth0.getAccessTokenSilently({ ...options, timeoutInSeconds: AUTH0_TIMEOUT_IN_SECONDS }).pipe(
            map((auth0Token) => {
                this.token.auth0Token = auth0Token;
                this.isGettingAuth0Token$.next(false);
            }),
            catchError(() => {
                this.isGettingAuth0Token$.next(false);
                // Unable to get the auth0 token silently means we need to log back in
                return this.redirectToLogin({
                    target: window.location.pathname + window.location.search, // Keep the current path in state so we return to the current page on login
                });
            })
        );
    }

    /**
     *  Attempts to retrieve Humi Token roles from the BE
     * @param type What authentication method is being used to get the roles (ie. login credentials, auth0 token). AUTH0 is default
     * @param data The body for the request.
     * @param urlParams A tuple of any params that need to be replaced in the url (ie. [':tokenId', 1234])
     * @returns The current token role for the authenticated user
     */
    retrieveHumiTokens(
        type: AuthType = 'AUTH0',
        data: Record<string, unknown> | null = { accessToken: this.token.auth0Token },
        urlParams?: [string, string]
    ): Observable<TokenRole | null> {
        // If a different request has already started to retrieve the token from the BE we wait for them to finish rather than sending a duplicate request
        if (this.isGettingAuth0Token$.value) {
            return this.waitForToken('HUMI').pipe(map(() => this.token.activeToken()));
        }
        const impersonation = localStorage.getItem('imp');

        const authConfig = AUTH_CONFIGS[type];
        const { body: authBodyConfig } = authConfig;

        // Replace any url parameters with their provided value, if present
        const url = urlParams ? authConfig.url.replace(urlParams[0], urlParams[1]) : authConfig.url;

        this.isGettingHumiTokens$.next(true);

        // If data is being sent in the request body, it may need to be serialized according to the auth type (credentials, auth0token, etc.)
        if (impersonation && type == 'AUTH0') {
            // When we exchange the impersonation token for role token(s),
            // we put a flag in our localstorage to capture the fact of an impersonation.
            // The API needs that flag to retrieve the impersonated account instead of the Auth0 authenticated account
            data = { ...data, impersonation: '1' };
        }
        let payload = data;
        if (authBodyConfig) {
            const serializedBody = authBodyConfig.serializer.serialize(data);
            payload = { ...serializedBody, data: { ...serializedBody.data, type: authBodyConfig.type } };
        }

        // Most auth calls should skip the interceptors that add headers
        // However, calls can be configured to still require one or both interceptors (ie. impersonations require Auth0Token header)
        const context = new HttpContext()
            .set(SKIP_HUMI_TOKEN, true)
            .set(SKIP_AUTH0_TOKEN, !authConfig.requireAuth0Token);

        return this.http
            .post(environment.api + url, payload, {
                context,
            })
            .pipe(
                map((response) => {
                    const currentTokenRole = this.token.setHumiTokens(deserialize(response));
                    this._authenticationMethod = type;
                    this.isGettingHumiTokens$.next(false);
                    return currentTokenRole;
                }),
                catchError((error) => {
                    this.isGettingHumiTokens$.next(false);
                    this.addAuthenticationErrorToAuth0State(error.message);
                    throw error;
                })
            );
    }

    /**
     * Tries to redirect back to Auth0 for login after a failure to hydrate the user
     */
    retryAuth0LoginAfterFailure(): void {
        this.incrementFailedAuth0LoginAttempts();
        this.redirectToLogin(this._auth0AppState);
    }

    /**
     * Hydrate all the authentication data for the
     * local user account
     */
    async hydrate(): Promise<any> {
        if (!this.token.accountId()) {
            await this.retrieveAllTokens().toPromise();
        }
        const accountId = this.token.accountId();
        const roleId = this.token.roleId();

        if (!accountId || !roleId) {
            throw new Error();
        }

        // Retrieve account first
        return Account.with(['employee'])
            .find(accountId)
            .then((account) => {
                this._account = account;

                const companyId = account.employee.companyId;

                // Retrieve additional information about
                // the authenticated user
                const requiredModels: [
                    Promise<Role>,
                    Promise<Employee>,
                    Promise<Company>,
                    Promise<{ data: { ids: number[] } }>,
                ] = [
                    Role.with(['permissions']).find(roleId),
                    Employee.param('company', companyId)
                        .with([
                            'account',
                            'activeModuleAssignments.module',
                            'activeTimeTrackingProjectOwnerships', // used to determine access in time tracking
                            'address',
                            'department',
                            'job',
                            'manager',
                            'office',
                            'benefitPlans',
                            'timeTrackingAttributes',
                        ])
                        .find(account.employee.id),
                    Company.with([
                        'logo',
                        'modules',
                        'reps',
                        'selfServeOnboarding', // used to determine the onboarding plan
                        'selfServeQuickstart', // used for showing/hiding the quickstart area
                        'setupGuides', // Used to get the setup guides for a company
                        'setupGuides.module',
                        'setupGuides.setupGuideSteps', // Gets the steps for those setup guides
                    ]).find(companyId),
                    this.http
                        .get<{
                            data: { ids: number[] };
                        }>(AppResources.AccessibleEmployeeIds.replace(':company', companyId?.toString()))
                        .toPromise(),
                ];

                return Promise.all(requiredModels).then(([role, employee, company, accessibleEmployeeIds]) => {
                    this._role = role;
                    this._employee = employee;
                    this._company = company;
                    this._modules = this.mapCompanyModules(
                        company.modules.map((module) => module.name),
                        true,
                        this._modules
                    );
                    this._accessibleEmployeeIds = accessibleEmployeeIds.data.ids;
                    this._hydrated = true;

                    this.analyticService.identifyUser(
                        this.employee,
                        this.employee.account,
                        this.role,
                        this.company,
                        this.company.modules
                    );

                    this._auth0AppState.failedLogin = { attempts: 0 };
                    this.onLogin.next();
                    this.onLoginStatusChange.next(true);
                    this.onHydrate.next();
                    this.setSentryUser();
                    return true;
                });
            });
    }

    async refreshCompany(): Promise<void> {
        const company = await Company.with([
            'logo',
            'modules',
            'reps',
            'selfServeOnboarding', // used to determine the onboarding plan
            'selfServeQuickstart', // used for showing/hiding the quickstart area
            'setupGuides', // Used to get the setup guides for a company
            'setupGuides.module',
            'setupGuides.setupGuideSteps', // Gets the steps for those setup guides
        ]).find(this.company.id);

        this._company = company;
    }

    /**
     * Check if the user is authenticated
     */
    get isAuthenticated(): boolean {
        return this._authenticationMethod !== null;
    }

    /**
     * Check if the user is hydrated
     */
    get hydrated(): boolean {
        return this._hydrated;
    }

    /**
     * Check if the local persistent tokens
     * are still valid
     */
    checkPersistentTokens(): Promise<void> {
        return this.http
            .get(AppResources.KeepAlive)
            .toPromise()
            .then(() => {
                // If the request to KeepAlive passes, we know the user is authenticated in the BE
                if (!this._authenticationMethod) {
                    // If for some reason we don't know how they authenticated, we use "KEEP-ALIVE" to indicate that they are authenticated but we don't know the original method
                    this._authenticationMethod = 'KEEP-ALIVE';
                }
            });
    }

    /**
     * A login via email and password in Humi's UI (Only available in non-deployed environments such as local and E2E)
     */
    authenticateWithCredentials(credentials: Credentials): Promise<LoginFlags> {
        return this.retrieveHumiTokens('CREDENTIALS', credentials)
            .pipe(switchMap(() => this.validateTokenRole()))
            .toPromise();
    }

    /**
     * A login via the oAuth route with a provided string exchange token (ie. impersonations)
     */
    authenticateWithExchangeToken(token: string, type?: AuthType): Promise<TokenRole | null> {
        if (!type) {
            type = 'SSO';
        }

        return this.retrieveHumiTokens(type, null, [':tokenId', token]).toPromise();
    }

    /**
     * Validates that the token role is valid and sets any applicable login flags
     * @param humiToken the current token role
     * @returns the login flags
     */
    validateTokenRole(): Observable<LoginFlags> {
        const responseAccountId: number = this.token.accountId();

        if (this._account && this._account.id !== responseAccountId) {
            throw new HttpErrorResponse({
                error: 'Logged in as a different user, refreshing page',
                status: 417,
            });
        }

        return this.http.get<JsonApiResponse<LoginFlags>>(AppResources.LoginFlags).pipe(
            map((response) => response.data.attributes),
            tap((flags) => (this._flags = flags)),
            catchError(() => {
                throw new HttpErrorResponse({
                    error: 'Account does not exist for this email',
                    status: 412,
                });
            })
        );
    }

    /**
     * Authenticate using Password Reset Token
     */
    authenticateWithPasswordResetToken(token: string): Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                const res = await this.http
                    .get<HttpResponse<any>>(AppResources.ResetPasswordAuth + '?token=' + token)
                    .toPromise();
                const response = deserialize(<any>res);
                this.token.setHumiTokens(response);
                resolve(res);
            } catch (err) {
                console.warn(err);
                reject(new JsonApiError(err));
            }
        });
    }

    /**
     * Authenticate with an account ID, used via the "login as" functionality for admin's on an employee's account profile
     */
    async authenticateWithAccountId(accountId: number): Promise<any> {
        return this.http
            .get(AppResources.Impersonate.replace(':accountId', accountId.toString()))
            .pipe(
                map((response) => {
                    const exchangeToken = deserialize(response)?.exchangeToken;
                    if (!exchangeToken) {
                        throw new Error('Could not acquire exchange token');
                    }
                    return exchangeToken;
                }),
                switchMap((exchangeToken) => this.authenticateWithExchangeToken(exchangeToken, 'IMPERSONATE')),
                tap(() => localStorage.setItem('imp', '1')),
                tap(() => (window.location.href = '/company'))
            )
            .toPromise();
    }

    /**
     * Activate
     */
    activate(token: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const res = await this.http
                    .get<HttpResponse<any>>(AppResources.Activate + '?token=' + token)
                    .toPromise();
                const response = deserialize(<any>res);
                this.token.setHumiTokens(response);
                resolve();
            } catch (err) {
                reject(new JsonApiError(err));
            }
        });
    }

    /**
     * Exchange an invitation token for a session token
     */
    activateInvitation(): Promise<void> {
        // At this point we don't have an Auth0 session or a Humi session so we cannot send either token
        const context = new HttpContext().set(SKIP_HUMI_TOKEN, true).set(SKIP_AUTH0_TOKEN, true);
        return this.http
            .get(AppResources.ActivateInvitation + '?token=' + this._activationToken, { context })
            .pipe(
                map((res) => {
                    const response = deserialize(res);
                    this.token.setHumiTokens(response);
                }),
                catchError((err) => {
                    Sentry.captureException(err);
                    throw new JsonApiError(err);
                })
            )
            .toPromise();
    }

    /**
     * Create password during account activation
     */
    storePassword(credentials: { value: string; valueConfirmation: string }): Promise<unknown> {
        if (!this.token.token) {
            throw new JsonApiSerializerError({ title: 'Account was activated but no authorization tokens exists' });
        }

        const body = STORE_PASSWORD_REQUEST_SERIALIZER.serialize({
            ...credentials,
            accountId: this.token.accountId(),
        });

        // At this point we still have not yet logged in with Auth0 so we cannot send the token
        const context = new HttpContext().set(SKIP_AUTH0_TOKEN, true);
        return this.http
            .post(AppResources.StorePassword.replace(':accountId', this.token.accountId().toString()), body, {
                context,
            })
            .pipe(
                map((res) => deserialize(res)),
                catchError((err) => {
                    Sentry.captureException(err);
                    throw new JsonApiError(err);
                })
            )
            .toPromise();
    }

    /**
     * Resets a password for an existing account
     */
    resetPassword(credentials: { email: string }): Promise<unknown> {
        const body = RESET_PASSWORD_REQUEST_SERIALIZER.serialize(credentials);
        return this.http
            .post(AppResources.ResetPassword, body)
            .pipe(
                catchError((err) => {
                    Sentry.captureException(err);
                    throw new JsonApiError(err);
                })
            )
            .toPromise();
    }

    /**
     * Keep Alive
     */
    keepAlive(): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            if (this.token.token) {
                try {
                    await this.http.get(AppResources.KeepAlive).toPromise();
                    resolve();
                } catch (err) {
                    reject(err);
                }
            } else {
                reject(null);
            }
        });
    }

    /**
     * Log the user out and clear LocalStorage
     */
    async logout(): Promise<any> {
        try {
            this.beforeLogout.next();
            await this.http.post(AppResources.Logout, {}).toPromise();
            this.token.clear();
            this.onLogout.next();
            this.onLoginStatusChange.next(false);
            this.clearAccountVariables();
            this.clearPersistedFormData();
            /**
             * Once we have every environment set up properly and auth0 simulated, this condition can be removed
             * */
            if (this.isAuth0AvailableInCurrentEnvironment) {
                await this.auth0Logout();
            } else {
                this.clearAccountVariables();
                this.router.navigate(['/login']);
            }
        } catch (e) {
            this.token.clear();
            this.clearAccountVariables();
            this.clearPersistedFormData();

            this.onLogout.next();
            this.onLoginStatusChange.next(false);
            this.router.navigate(['/login']);
        }
    }

    async auth0Logout(): Promise<void> {
        await this.auth0
            .logout({
                logoutParams: {
                    returnTo: environment.url + '/login',
                },
            })
            .pipe(
                catchError((error) => {
                    Sentry.captureException(error);
                    this.router.navigate(['/login']);
                    return of(void 0);
                })
            )
            .toPromise();
    }

    auth0LogoutToErrorPage(message: string): Observable<void> {
        localStorage.setItem(AUTH_ERROR, message);
        this.token.removeAuth0Token();
        return this.auth0.logout({
            logoutParams: {
                returnTo: environment.url + '/client-error',
            },
        });
    }

    async checkThenReauth(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.keepAlive()
                .then(() => {
                    resolve();
                })
                .catch(() => {
                    this.reauthenticate()
                        .then(() => {
                            resolve();
                        })
                        .catch((error) => {
                            reject(error);
                        });
                });
        });
    }

    reauthenticate(): Promise<void> {
        // Local and E2E will send the user back to the login screen
        if (environment.localStorageTokens) {
            return this.logout();
        }
        // Deployed environments will reach out to Auth0 first for session
        // If not available, it will send the user to the Auth0 login screen
        // If it is available, it will use this to retrieve a new set of tokens from the Humi BE
        return this.retrieveAllTokens({
            cacheMode: 'off', // Required to actually reach out to Auth0, otherwise cached value will assume session is still active
        }).toPromise();
    }

    /**
     * Checks to ensure a token sent in an account activation invite is valid before allowing a user to register
     */
    checkActivationToken(
        token: string
    ): Promise<JsonApiResponse<{ email: string; firstName: string; expired: boolean }>> {
        const context = new HttpContext().set(SKIP_HUMI_TOKEN, true).set(SKIP_AUTH0_TOKEN, true);
        return this.http
            .get<
                JsonApiResponse<{ email: string; firstName: string; expired: boolean }>
            >(AppResources.CheckActivationToken + '?token=' + token, { context })
            .toPromise();
    }

    checkCommonPassword(password: string): Promise<boolean> {
        const serializer = new Serializer('passwords', {
            keyForAttribute: 'camelCase',
            attributes: ['value'],
        });

        const body = serializer.serialize({ value: password });
        return new Promise<boolean>((resolve, reject) => {
            this.http
                .post(AppResources.CheckCommonPassword, body)
                .toPromise()
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    reject(false);
                });
        });
    }

    checkEmailAvailable(email: string): Promise<boolean> {
        const serializer = new Serializer('accountEmails', {
            keyForAttribute: 'camelCase',
            attributes: ['address'],
        });
        const body = serializer.serialize({ address: email });

        return new Promise<boolean>((resolve, reject) => {
            this.http
                .post(AppResources.EmailAvailable, body)
                .toPromise()
                .then(() => {
                    resolve(true);
                })
                .catch(() => {
                    reject(false);
                });
        });
    }

    /**
     * Send invites to array of accounts
     * can be used for 1 or bulk
     */
    sendInvitations(accountIds: number[], companyId?: number): Promise<any> {
        if (!this._company) {
            throw new Error('No auth company. Cannot send invitations');
        }

        if (!companyId) {
            companyId = this._company.id;
        }

        return new Promise((resolve, reject) => {
            this.http
                .post<HttpResponse<any>>(
                    AppResources.BulkInvite.replace(':company', companyId!.toString()),
                    this.getBulkInvitePayload(accountIds)
                )
                .toPromise()
                .then((res: HttpResponse<any>) => resolve(res))
                .catch((err: any) => reject(err));
        });
    }

    canAccessEmployee(value: number | Employee): boolean {
        if (!this._employee) {
            return false;
        }

        if (this.can(Platform.manage.all)) {
            return true;
        }

        const id = value instanceof Employee ? value.id : value;

        if (this._employee.id === id) {
            return true;
        }

        if (this.accessibleEmployeeIds.includes(id)) {
            return true;
        }

        return false;
    }

    /**
     * @deprecated
     * Check a specific model against the access level and permissions of an employee
     */
    hasManageAccess(model: Model, key = 'id', checkAccount = false): boolean {
        if (!this._account || !this._employee) {
            return false;
        }

        if (this.canAll(Platform.manage.all)) {
            return true;
        }

        const checkId = checkAccount ? this._account.id : this._employee.id;
        let directReport = false;
        let self = false;

        try {
            directReport = this.accessibleEmployeeIds.includes(+model.byString(key));
            self = checkId === +model.byString(key);
        } catch (e) {
            console.warn('Trying to access key of model during access level check that doesnt exist', e);
        }

        if (this.canAll(Platform.manage.directAndIndirectReports) && (directReport || self)) {
            return true;
        }

        if (this.canAll(Platform.manage.directReports) && (directReport || self)) {
            return true;
        }
        // Add Direct and Indirect
        if (this.canAll(Platform.manage.self) && self) {
            return true;
        }

        return false;
    }

    /**
     * Checks if the supplied employee has a certain permission in the app.
     *
     * NOTE: Unlike other "can" functions, this returns an observable because it makes an API call, this should be used sparingly.
     * @param employee The employee to check permissions of
     * @param permissionName The name of the permission (ie. "accessPayroll")
     * @returns An observable of true if they have the permission or false otherwise
     */
    canEmployee(employee: Employee, permissionName: string): Observable<boolean> {
        return from(
            Permission.where('companyId', employee.companyId)
                .where('roleIds', employee.account.roles?.map((role) => role.id).join(','))
                .all()
        ).pipe(
            take(1),
            map(([permissions]: Permission[][]) =>
                permissions.some((permission: Permission) => permission.name === permissionName)
            )
        );
    }

    /**
     * Check a specific permissions exists on the Role model permission relation
     */
    canAll(permission: string[]): boolean {
        if (!this._role || !this._role.permissions) {
            return false;
        }
        if (!permission.length) {
            return false;
        }
        let matchAll = true;
        for (const p of permission) {
            if (!this._role.permissions.some((perm: Permission) => perm.name === p)) {
                matchAll = false;
            }
        }
        return matchAll;
    }

    canAny(permission: string[]): boolean {
        if (!this._role || !this._role.permissions) {
            return false;
        }
        if (!permission.length) {
            return false;
        }
        let matchAny = false;
        for (const p of permission) {
            if (this._role.permissions.some((perm: Permission) => perm.name === p)) {
                matchAny = true;
            }
        }
        return matchAny;
    }

    /**
     * Checks if the company has the module
     * NOTE: this will always return false prior to hydration
     */
    companyHasModule(moduleName: PlatformModules): boolean {
        return !!this._modules[moduleName];
    }

    /**
     * Checks if the employee has the module
     * NOTE: this will always return false prior to hydration
     */
    employeeHasModule(moduleName: PlatformModules): boolean {
        return this.hydrated && this.employee.hasModule(moduleName);
    }

    get isImpersonating(): boolean {
        return this.token.impersonation;
    }

    private incrementFailedAuth0LoginAttempts(): void {
        if (this._auth0AppState.failedLogin) {
            this._auth0AppState.failedLogin.attempts += 1;
        } else {
            this._auth0AppState.failedLogin = { attempts: 1 };
        }
    }

    private addAuthenticationErrorToAuth0State(error: string): void {
        if (this._auth0AppState.failedLogin) {
            this._auth0AppState.failedLogin.message = error;
        } else {
            this._auth0AppState.failedLogin = { attempts: 1, message: error };
        }
    }

    /**
     *
     * This function subscribes to the observable that notifies state changes after the auth0 handleRedirectCallback function is called.
     */
    private subscribeToAuth0State(): void {
        this.auth0.appState$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((appState: Auth0AppState) => {
            this._auth0AppState = appState;
            // We track the number of times we've failed to login so that we don't infinitely redirect between Humi and Auth0
            if (appState.failedLogin?.attempts && appState.failedLogin.attempts > MAX_RETRY_FAILED_LOGIN_ATTEMPTS) {
                this.authenticationError = appState.failedLogin?.message ?? 'Unable to login';
            }
        });
    }

    /**
     * When requesting a token sometimes a different function in the code has already started requesting. In these cases we don't want to fire off another
     * token request since it is a waste of resources and could result in invalidating the token. Instead this function waits for the original request to finish
     * getting the token and then completes.
     * @param tokenType 'HUMI' for retrieving a token from the backend. 'AUTH0' for retrieving a token from Auth0
     * @returns An observable that completes when the original request for a token finishes
     */
    private waitForToken(tokenType: 'HUMI' | 'AUTH0'): Observable<void> {
        const tokenObservable = tokenType === 'HUMI' ? this.isGettingHumiTokens$ : this.isGettingAuth0Token$;
        return tokenObservable.pipe(
            takeWhile(Boolean, true),
            ignoreElements(),
            timeout(5000) // Ensures requests don't get stuck indefinitely, realistically should never be hit
        );
    }

    /**
     * Check a specific permissions exists on the Role model permission relation
     */
    private canOne(permission: string): boolean {
        if (!this._role || !this._role.permissions) {
            return false;
        }
        return this._role.permissions.some((perm: Permission) => perm.name === permission);
    }

    /**
     * JSON API payload for invite request
     */
    private getBulkInvitePayload(accountIds: number[]): object {
        return {
            data: {
                type: 'InviteRequest',
                attributes: {
                    accountIds: accountIds,
                },
            },
        };
    }

    private setSentryUser(): void {
        Sentry.setUser({
            email: this.account.email,
            id: this.employee.id.toString(),
        });
    }

    private clearAccountVariables(): void {
        this._employee = null;
        this._account = null;
        this._authenticationMethod = null;
        this._hydrated = false;
    }

    private clearPersistedFormData(): void {
        Object.keys(localStorage)
            .filter((key) => key.startsWith(FORM_PERSISTENCE_PREFIX))
            .forEach((key) => localStorage.removeItem(key));
    }

    /**
     * Takes an array of modules and makes a mapped object for easy reference
     * @param modules array of module names to mapped into an object
     * @param hasModule whether we are setting the map value to true or false
     * @param initialMap if we are mapping onto an existing object
     * @returns a map of module names to true/false ie. ({ Benefits: false, Payroll: true })
     */
    private mapCompanyModules(
        modules: PlatformModules[],
        hasModule: boolean,
        initialMap = {}
    ): Partial<Record<PlatformModules, boolean>> {
        return modules.reduce((accumulator, moduleName) => ({ ...accumulator, [moduleName]: hasModule }), initialMap);
    }
}

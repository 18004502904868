import { css, nothing } from 'lit';
import { HumiLogoLabelOrientation } from './types';

/**
 * Moves the "H" and swirl in the case of a vertical label orientation in order to provide horizontal centering
 */
export const getHorizontalOffset = (labelOrientation?: HumiLogoLabelOrientation) =>
    labelOrientation === 'vertical'
        ? css`
              transform: translateX(80px);
          `
        : nothing;

import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { FeatureFlagKey } from '@app/enums';
import { FeatureService } from '@app/services/feature.service';

@Directive({
    standalone: true,
    selector: '[showIfFlag]',
})
/**
 * Only shows the element it's attached to if the Feature-Flag evaluates to true
 */
export class ShowIfFeatureFlagTrueDirective implements OnInit {
    @Input({ required: true }) showIfFlag!: FeatureFlagKey;

    constructor(
        private templateRef: TemplateRef<unknown>,
        private viewContainer: ViewContainerRef,
        private featureService: FeatureService
    ) {}

    async ngOnInit(): Promise<void> {
        if (await this.featureService.has(this.showIfFlag)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    }
}

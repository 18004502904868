import { Pipe, PipeTransform } from '@angular/core';
import { Country } from '@app/models/common/country.model';
import { CountryService } from '@app/services/country.service';
import { TranslationStorageService } from '@app/services/translation-storage.service';

@Pipe({
    name: 'countryName',
    pure: false,
})
export class CountryNamePipe implements PipeTransform {
    private countries: Country[] = [];

    constructor(
        private countryService: CountryService,
        private translationStorage: TranslationStorageService
    ) {
        this.countryService.allCountries.subscribe((countries: Country[]) => {
            this.countries = countries;
        });
    }

    transform(value: string): string {
        const locale = this.translationStorage.getLocale();
        const country = this.countries.find((c) => c.name === value || c.frenchName === value || c.code === value);
        if (country) {
            return locale === 'fr-CA' ? country.frenchName : country.name;
        }
        return value;
    }
}

import { MenuService } from '@app/services/menu/menu.service';
import { Injectable } from '@angular/core';
import { MenuItem } from '@app/interfaces';
import { BenefitPlan } from '@models/benefits/benefit-plan.model';
import { Platform } from '@app/classes';

const BenefitsMenu: MenuItem[] = [
    {
        key: 'benefits.plans',
        link: ['/benefits', 'plans'],
        can: [...Platform.benefits.permission.view, ...BenefitPlan.permission.view],
    },
    {
        key: 'benefits.settings',
        link: ['/benefits', 'settings'],
        can: [...Platform.benefits.permission.view, ...BenefitPlan.permission.edit],
    },
];

@Injectable()
export class BenefitsMenuService extends MenuService {
    setBaseMenu(): void {
        this.baseMenu = BenefitsMenu;
    }
}

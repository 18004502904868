import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChange,
    ViewChild,
} from '@angular/core';
import { warnDeveloper } from '@app/functions';
import { isValidUrl } from '@app/functions/is-valid-url';
import { getOpenStatus } from '@app/functions/ngChangeUtils/getOpenStatus';
import { shadows } from '@app/styles/theme';
import { css } from 'emotion';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';
import { BannerVariant } from '../platform/banner/banner.component';
import { overlayAnimationTime } from '../platform/overlay.component';

export interface BannerConfig {
    variant: BannerVariant;
    closable: boolean;
}

class BannerController implements BannerConfig {
    variant: BannerVariant;
    closable: boolean;
    open = true;

    constructor({ variant, closable }: BannerConfig) {
        this.variant = variant;
        this.closable = closable;
    }
}

const dialogControlsHeight = 64;
const bannerPreRenderMultiplier = 0.8;

@Component({
    selector: 'app-pdf-viewer-dialog',
    template: `<ui-overlay [open]="open" [centerContent]="false">
        <div [ngClass]="[styles.toolbar]">
            <app-pdf-viewer-dialog-controls
                *ngIf="!loading"
                (closing)="onClose()"
                [name]="name"
                [page]="viewer?.page"
                [pages]="pages"
                (pageChange)="viewer?.loadPage($event)"
                (buttonClicked)="onButtonClicked()"
                [buttonLabel]="buttonLabel"
            ></app-pdf-viewer-dialog-controls>
        </div>

        <ui-banner
            *ngIf="_bannerController?.open"
            [ngClass]="styles.banner"
            [variant]="_bannerController.variant"
            [closable]="_bannerController.closable"
            (close)="onDismissBanner()"
        >
            <ng-content select=".banner-content"></ng-content>
        </ui-banner>

        <div tabIndex="0" class="padding-bottom-8" [ngClass]="styles.viewerContainer" (click)="onClose()">
            <mat-spinner *ngIf="loading" class="align-self-center margin-auto"></mat-spinner>

            <div [class.visible]="!loading" class="flex justify-center padding-top-5" (click)="negateClick($event)">
                <app-pdf-viewer
                    #viewer
                    [scale]="1.5"
                    [showingControls]="false"
                    (pdfRendered)="onPdfRendered()"
                ></app-pdf-viewer>
            </div>
        </div>
    </ui-overlay>`,
})
export class PdfViewerDialogComponent implements OnChanges, OnInit {
    @ViewChild('viewer') viewer: PdfViewerComponent;
    @Output() buttonClicked: EventEmitter<void> = new EventEmitter();
    @Output() closing: EventEmitter<void> = new EventEmitter();
    @Input() open = false;
    @Input() src: string | null = null;
    @Input() name: string;
    @Input() buttonLabel: string | null = null;
    @Input() set bannerConfig(bannerConfig: BannerConfig) {
        if (!bannerConfig) {
            this._bannerController = null;
            this._topOffset = dialogControlsHeight;
            return;
        }

        this._bannerController = new BannerController(bannerConfig);
    }

    _topOffset = dialogControlsHeight;
    _bannerController: BannerController | null;

    loading = true;
    firstRender = true;
    pages: number;

    styles = {
        toolbar: css`
            background: var(--grey-800);
            box-shadow: ${shadows.dialog};
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            z-index: 10;
            height: ${dialogControlsHeight}px;
        `,
        banner: css`
            position: fixed;
            left: 0;
            right: 0;
            top: ${dialogControlsHeight}px;
        `,
        viewerContainer: css`
            position: relative;
            overflow-y: auto;
            height: calc(100% - ${dialogControlsHeight}px);
            margin-top: ${dialogControlsHeight}px;

            mat-spinner {
                position: absolute;
                top: 250;
                left: 0;
                right: 0;
            }
        `,
    };

    /**
     * Store the source url until the viewer opens.  Once opened this reference will be deleted
     * this is done to make sure the canvas element renders at the correct height on opening.
     * */
    private _tmpSourceUrl: string;

    constructor(private _element: ElementRef) {}

    ngOnInit(): void {
        this.verifyButtonCorrectlyConfigured();
    }

    ngOnChanges(changes: Partial<Record<'src' | 'open' | 'bannerConfig', SimpleChange>>): void {
        if ('src' in changes && isValidUrl(changes?.src?.currentValue)) {
            this.setSource(changes.src.currentValue);
        }

        const [open, closed] = getOpenStatus(changes?.open);

        if (open && this._tmpSourceUrl) {
            this.viewer.setSource(this._tmpSourceUrl).then(() => {
                delete this._tmpSourceUrl;
                setTimeout(
                    () => (this._topOffset = dialogControlsHeight + this._getBannerHeight()),
                    overlayAnimationTime * bannerPreRenderMultiplier
                );
            });
        }

        if (closed) {
            this.firstRender = true;
        }
    }

    async setSource(pdfSourceUrl: string, name?: string): Promise<void> {
        this.loading = true;

        this.src = pdfSourceUrl;
        if (name) {
            this.name = name;
        }

        if (!this.open) {
            this._tmpSourceUrl = pdfSourceUrl;
            return;
        }

        this.viewer.setSource(pdfSourceUrl);
    }

    onButtonClicked(): void {
        this.buttonClicked.emit();
    }

    onClose(): void {
        this.closing.emit();

        if (this._bannerController) {
            this.resetBanner();
        }

        this.pages = 0;
    }

    onDismissBanner(): void {
        this._topOffset = dialogControlsHeight;
        this._bannerController.open = false;
    }

    async onPdfRendered(): Promise<void> {
        this.pages = this.viewer.totalPages;
        this.loading = false;

        // Forces pdf to render properly as sometimes the pdf won't be properly shown until the view resizes
        if (this.firstRender) {
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
                this.firstRender = false;
            }, 100);
        }
    }

    negateClick(event: MouseEvent): void {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('window:keydown', ['$event'])
    handleKey(event: KeyboardEvent): void {
        switch (event.key) {
            case 'Escape':
                this.onClose();
                break;
        }
    }

    private resetBanner(): void {
        // Don't want it to pop in as the dialog is fading out
        setTimeout(() => {
            this._bannerController.open = true;
        }, overlayAnimationTime);
    }

    private verifyButtonCorrectlyConfigured(): void {
        const buttonClickedListenersExist = this.buttonClicked.observers.length > 0;
        const hasValidButtonLabel = !!this.buttonLabel?.trim();

        const showButtonConfigured = buttonClickedListenersExist && hasValidButtonLabel;
        const hideButtonConfigured = !buttonClickedListenersExist && !hasValidButtonLabel;

        if (showButtonConfigured || hideButtonConfigured) {
            return;
        }

        // Otherwise, warn the developer.
        const warnings = ['PDF Preview Dialog', 'Incorrect action button configuration.'];

        if (!buttonClickedListenersExist) {
            warnings.push('Must have a listener for the `buttonClicked` event.');
        }

        if (!this.buttonLabel) {
            warnings.push('Must provide a non-null, non-empty button label.');
        }

        warnDeveloper(warnings);
    }

    private _getBannerHeight(): number {
        if (!this._bannerController?.open) {
            return 0;
        }

        const banner = this._element.nativeElement.querySelector('ui-banner');
        return banner?.offsetHeight || 0;
    }
}

import { Injectable } from '@angular/core';
import { FeatureFlag, FeatureFlagKey } from '@app/enums';
import { canMockFeatureFlags } from '@app/functions/feature-flags.helpers';
import { AuthService } from '@app/services/auth.service';
import { LaunchDarklyFeatureService } from '@app/services/launch-darkly-feature.service';
import { LocalStorageFeatureService } from '@app/services/local-storage-feature.service';
import { FeatureInterface } from '@interfaces/feature.interface';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
    useFactory: (auth: AuthService): FeatureInterface =>
        canMockFeatureFlags() ? new LocalStorageFeatureService() : new LaunchDarklyFeatureService(auth),
    deps: [AuthService],
})
export abstract class FeatureService implements FeatureInterface {
    abstract isClientReady: Observable<boolean>;
    abstract isLocal: boolean;
    abstract all(): Promise<LDFlagSet>;
    abstract has(flag: FeatureFlag | FeatureFlagKey): Promise<boolean>;
    abstract hasMany(...flags: FeatureFlag[]): Promise<boolean[]>;
}

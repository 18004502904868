import { Model } from '@models/core/base.model';
import { EmployeeBenefit } from '@models/payroll/employee-benefit.model';
import { PayrollEmployee } from '@models/payroll/payroll-employee.model';
import { BenefitType } from './benefit-type.model';
import { BenefitDeductionFrequency } from './company/benefit-deduction-frequency.model';
import { T4Box } from './t4-box.model';

export class Benefit extends Model {
    protected static _resource = 'payroll/companies/:company/benefits';

    protected static _version = 'v2';

    protected static _serializeAttributes = [
        'name',
        'attach',
        'detach',
        'benefitTypeId',
        'glCodeExpense',
        'glCodePayable',
        'benefitDeductionFrequencyUuid',
        /* deprecated */
        'provider',
        'description',
        'defaultCompanyContribution',
        'defaultEmployeePremium',
        'cash',
        'pretax',
        'taxable',
        'insurable',
        'pensionable',
        'addToEmployees',
    ];

    get name(): string {
        return this._attributes['name'];
    }

    set name(val: string) {
        this._attributes['name'] = val;
    }

    get glCodeExpense(): string {
        return this._attributes['glCodeExpense'];
    }

    set glCodeExpense(val: string) {
        this._attributes['glCodeExpense'] = val;
    }

    get glCodePayable(): string {
        return this._attributes['glCodePayable'];
    }

    set glCodePayable(val: string) {
        this._attributes['glCodePayable'] = val;
    }

    set benefitTypeId(val: number) {
        this._attributes['benefitTypeId'] = val;
    }

    get benefitTypeId(): number {
        return this._attributes['benefitTypeId'];
    }

    get benefitTypeDisplayName(): string {
        return this._attributes['benefitTypeDisplayName'];
    }

    get benefitType(): BenefitType {
        return this.hasOne(BenefitType, 'benefitType');
    }

    get benefitDeductionFrequency(): BenefitDeductionFrequency | null {
        return this.hasOne(BenefitDeductionFrequency, 'benefitDeductionFrequency');
    }

    get employeeBenefits(): EmployeeBenefit[] {
        return this.hasMany(EmployeeBenefit, 'employeeBenefits');
    }

    /**
     * Deprecated
     */

    get provider(): string {
        return this._attributes['provider'];
    }

    set provider(val: string) {
        this._attributes['provider'] = val;
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get defaultCompanyContribution(): number {
        return this._attributes['defaultCompanyContribution'];
    }

    set defaultCompanyContribution(val: number) {
        this._attributes['defaultCompanyContribution'] = val;
    }

    get defaultEmployeePremium(): number {
        return this._attributes['defaultEmployeePremium'];
    }

    set defaultEmployeePremium(val: number) {
        this._attributes['defaultEmployeePremium'] = val;
    }

    get cash(): string {
        return this._attributes['cash'];
    }

    set cash(val: string) {
        this._attributes['cash'] = val;
    }

    get pretax(): string {
        return this._attributes['pretax'];
    }

    set pretax(val: string) {
        this._attributes['pretax'] = val;
    }

    get taxable(): string {
        return this._attributes['taxable'];
    }

    set taxable(val: string) {
        this._attributes['taxable'] = val;
    }

    get insurable(): string {
        return this._attributes['insurable'];
    }

    set insurable(val: string) {
        this._attributes['insurable'] = val;
    }

    get pensionable(): string {
        return this._attributes['pensionable'];
    }

    set pensionable(val: string) {
        this._attributes['pensionable'] = val;
    }

    get addToEmployees(): string {
        return this._attributes['addToEmployees'];
    }

    set addToEmployees(val: string) {
        this._attributes['addToEmployees'] = val;
    }

    get employees(): PayrollEmployee[] {
        return this.hasMany(PayrollEmployee, 'employees');
    }

    get t4Boxes(): T4Box[] {
        return this.hasMany(T4Box, 't4Boxes');
    }

    get benefitDeductionFrequencyUuid(): string {
        return this._attributes['benefitDeductionFrequencyUuid'];
    }
    set benefitDeductionFrequencyUuid(val: string) {
        this._attributes['benefitDeductionFrequencyUuid'] = val;
    }
}

@if (isRedirecting) {
    <div class="loadable loading"></div>
} @else {
    <div class="timeout-message text-small" [class.is-visible]="isTimedOut" [attr.aria-hidden]="!isTimedOut">
        {{ 'login.timeout.message' | translate }}&nbsp;<a
            [tabIndex]="isTimedOut ? 0 : -1"
            (click)="handleRedirectClick()"
            >{{ 'login.timeout.clickHere' | translate }}</a
        >.
    </div>
}

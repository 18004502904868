import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { isUndefined } from 'lodash-es';

export const AUTH0_HEADER = 'Auth0token';
const CURRENT_ROLE = 'currentRole';

declare let localStorage: Storage;

interface LegacyTokenRole {
    [name: string]: {
        id: number;
        token: string;
    };
}

export type TokenRole = {
    roleId: number;
    roleName: string;
    token: string;
    companyId: number | null;
    companyName: string | null;
};

export type TokenResponse = {
    id: number;
    tokens: TokenRole[];
    impersonation?: boolean;
};

/**
 * Converts legacy (big object) tokens to TokenRole[]
 * Because clients keep tokens in localStorage, this cannot be safely removed
 * for a long time. Maybe... September 2021?
 */
export const transformTokensToNewStandard = (tokens: TokenRole[] | LegacyTokenRole): TokenRole[] => {
    if (Array.isArray(tokens)) {
        return tokens as TokenRole[];
    }

    // legacy object
    const roleNames = Object.keys(tokens);

    return roleNames.map(
        (roleName): TokenRole => ({
            roleId: tokens[roleName].id,
            roleName,
            token: tokens[roleName].token,
            companyName: null,
            companyId: null,
        })
    );
};

@Injectable()
export class TokenService {
    private _accountId: number | null = null;
    private _tokenRoles: TokenRole[] = [];
    private _activeTokenRole: TokenRole | null = null;
    private _auth0Token: string | null = null;
    private _impersonation = false;

    constructor() {
        if (this.getPersistentTokens()) {
            this.useInitialRole();
        }
    }

    hasMultipleCompanies(): boolean {
        const companyIds = this._tokenRoles.filter(({ companyId }) => companyId).map(({ companyId }) => companyId);

        return new Set(companyIds).size > 1;
    }

    hasMultipleRolesAtCurrentCompany(): boolean {
        return this.tokensForCurrentCompany().length > 1;
    }

    /**
     * Returns an array of token roles, but only 1 per company
     */
    uniqueCompanyTokenRoles(): TokenRole[] {
        return this._tokenRoles
            .filter(({ companyId }) => companyId)
            .reduce(
                (accumulator, current) =>
                    accumulator.some((item) => item.companyId === current.companyId)
                        ? accumulator
                        : [...accumulator, current],
                []
            )
            .sort((a: TokenRole, b: TokenRole) => a.companyName.localeCompare(b.companyName));
    }

    get token(): string | null {
        if (this._activeTokenRole?.token) {
            return this._activeTokenRole?.token;
        } else if (environment.localStorageTokens) {
            // This functionality ONLY executes in e2e and local environments. Other environments do not retrieve values from Local Storage
            const localRole = environment.localStorageTokens.get();
            if (localRole) {
                this._accountId = localRole.id;
                this._tokenRoles = localRole.tokens;
                this._impersonation = localRole?.impersonation ?? false;
                return this.useInitialRole().token;
            }
        }
        return null;
    }

    roleId(): number | null {
        return this._activeTokenRole?.roleId || null;
    }

    roleName(): string | null {
        return this._activeTokenRole?.roleName || null;
    }

    tokens(): TokenRole[] {
        return this._tokenRoles;
    }

    activeToken(): TokenRole | null {
        return this._activeTokenRole;
    }

    get auth0Token(): string | null {
        return this._auth0Token;
    }

    set auth0Token(accessToken: string | null) {
        this._auth0Token = accessToken;
    }

    removeAuth0Token(): void {
        this._auth0Token = null;
    }

    tokensForCurrentCompany(): TokenRole[] {
        if (!this._activeTokenRole) {
            return [];
        }

        // handle tokens that don't YET have companyId
        // can be removed in October 2020
        if (!this._activeTokenRole.companyId) {
            return this._tokenRoles;
        }

        return this._tokenRoles.filter((tokenRole) => tokenRole.companyId === this._activeTokenRole.companyId);
    }

    accountId(): number {
        return this._accountId;
    }

    get impersonation(): boolean {
        return this._impersonation;
    }

    /**
     * Changes the user's current role OR company (in the case of a multi-company user)
     * This is done by switching the token used for authentication with the BE.
     *
     * Only the roleName and companyId are persisted via LocalStorage for security purposes.
     * Using LocalStorage allows the current role to persist after refreshes and tab switches
     * @param roleId the roleId of the token to be switched to. Alternatively, the entire token object can be provided
     * @returns boolean whether the role change was successful
     */
    changeRole(roleId: number): boolean;

    /**
     * Changes the user's current role OR company (in the case of a multi-company user)
     * This is done by switching the token used for authentication with the BE.
     *
     * Only the roleName and companyId are persisted via LocalStorage for security purposes.
     * Using LocalStorage allows the current role to persist after refreshes and tab switches
     * @param tokenRole the entire token object to be switched to. Alternatively, just the roleId can be provided
     * @returns boolean whether the role change was successful
     */
    changeRole(tokenRole: TokenRole): boolean;

    changeRole(role: number | TokenRole): boolean {
        const tokenRole = typeof role === 'number' ? this.getTokenByRoleId(role) : role;
        if (tokenRole && this._tokenRoles.includes(tokenRole)) {
            const { roleName, companyId } = tokenRole;
            localStorage.setItem(CURRENT_ROLE, JSON.stringify({ roleName, companyId }));
            this._activeTokenRole = tokenRole;
            return true;
        }
        return false;
    }

    clear(): void {
        this._accountId = null;
        this._tokenRoles = [];
        this._activeTokenRole = null;
        this._impersonation = false;
        localStorage.removeItem(CURRENT_ROLE);
        localStorage.removeItem('imp');
        environment.localStorageTokens?.clear();
        this.removeAuth0Token();
    }

    /**
     * Set the company id into LocalStorage. If this is done prior to the call to exchangeAccessToken then
     * this preference can be used to ensure the app loads for the user under a specific company when they have multiple companies.
     *
     * If an invalid companyId is stored, it will be discarded during useInitialRole()
     */
    setCompanyToken(companyId: number): void {
        // If the user already has a session under the requested company ID then we don't need to do anything
        if (this.currentRole?.companyId === companyId) {
            return;
        }
        localStorage.setItem(CURRENT_ROLE, JSON.stringify({ companyId }));
    }

    getPersistentTokens(): boolean {
        //TODO: this following check will need to be updated once Auth0 is live
        return !this._tokenRoles?.length || !this._accountId || isNaN(+this._accountId);
    }

    setHumiTokens(payload: TokenResponse): TokenRole {
        this._accountId = payload.id;
        const tokens = transformTokensToNewStandard(payload.tokens);
        this._tokenRoles = tokens;
        this._impersonation = payload?.impersonation ?? false;
        // This line ONLY executes in e2e and local environments. Other environments do not store in Local Storage
        environment.localStorageTokens?.set(payload);
        return this.useInitialRole();
    }

    /**
     * Checks if the user has a role currently set in LocalStorage, otherwise return undefined
     * NOTE: this only returns companyId and roleName, other TokenRole properties can not be returned since they are not stored in LocalStorage
     */
    private get currentRole(): { companyId: number; roleName?: string } | undefined {
        const currentRoleAndCompany = localStorage.getItem(CURRENT_ROLE);
        if (currentRoleAndCompany) {
            try {
                const { companyId, roleName } = JSON.parse(currentRoleAndCompany);

                // companyId is required, but the role is optional
                if (typeof companyId === 'number' && (typeof roleName === 'string' || isUndefined(roleName))) {
                    return { companyId, roleName };
                }
                throw new Error('Invalid JSON, resetting LocalStorage variable');
            } catch {
                localStorage.removeItem(CURRENT_ROLE);
            }
        }
        return undefined;
    }

    private useInitialRole(): TokenRole {
        // Check if the user already has a role set via LocalStorage (this is persisted through tabs and refreshes)
        const currentRoleAndCompany = this.currentRole;
        if (currentRoleAndCompany) {
            const { roleName: currentRole, companyId: currentCompanyId } = currentRoleAndCompany;
            let tokenRoleMatchingCurrentRole;
            if (currentCompanyId) {
                // Sometimes only a company is stored in LocalStorage, in which case we still want to select a token from that
                // company, but don't know which role, so will select the first or admin role
                if (!currentRole) {
                    const companyRoles = this._tokenRoles.filter(({ companyId }) => currentCompanyId === companyId);
                    if (companyRoles.length) {
                        tokenRoleMatchingCurrentRole = this.findFirstOrAdminRole(companyRoles);
                    }
                } else {
                    // If we have both a role and a company ID then we try to find a specific token
                    tokenRoleMatchingCurrentRole = this._tokenRoles.find(
                        ({ roleName, companyId }) => currentRole === roleName && currentCompanyId === companyId
                    );
                }
            }

            if (tokenRoleMatchingCurrentRole) {
                this._activeTokenRole = tokenRoleMatchingCurrentRole;
                return this._activeTokenRole;
            }
        }

        // If they don't have a current role set, use the first admin role if it exists, otherwise just use the first role
        const tokenRole = this.findFirstOrAdminRole(this._tokenRoles);

        this.changeRole(tokenRole);
        return tokenRole;
    }

    private getTokenByRoleId(roleId: number): TokenRole | undefined {
        return this._tokenRoles.find((token) => token.roleId === roleId);
    }

    /**
     * If we don't know a user's preferred role, we will always default to an admin role if it exists, otherwise we just select the first available
     */
    private findFirstOrAdminRole(roles: TokenRole[]): TokenRole {
        const adminTokenRole = roles.find((tokenRole) => tokenRole.roleName.includes('Admin'));
        const firstTokenRole = roles[0];
        return adminTokenRole || firstTokenRole;
    }
}

import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@app/classes';
import { FeatureFlag } from '@app/enums';
import { Company } from '@app/models/company/company.model';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Employee } from '@app/models/employee/employee.model';
import { Languages } from '@app/modules/account/enums/languages.enum';
import { THEME_PREFERENCES } from '@app/scripts/theme/theme';
import { AuthService, LanguageService, NotifyService, TokenService, UserAccessService } from '@app/services';
import { FeatureService } from '@app/services/feature.service';
import { TransifexToggleService } from '@app/services/language/transifex-toggle.service';
import { TranslateDebuggerStoreService } from '@app/services/language/translate-debugger-store.service';
import { MobileSidenavService } from '@app/services/mobile-sidenav.service';
import { ThemeService } from '@app/services/theme.service';
import { TokenRole } from '@app/services/token.service';
import { TranslationStorageService } from '@app/services/translation-storage.service';
import { environment } from '@env/environment';
import { EmployeeAutocompleteComponent } from '../platform';

declare let window: any;

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.template.html',
    host: { role: 'banner' },
    styleUrls: ['./topbar.style.scss'],
})
export class TopbarComponent implements OnInit {
    @ViewChild('employeeSearch', { static: true }) employeeSearch: EmployeeAutocompleteComponent;
    company: Company = this.auth.company;
    hasMultipleCompanies = this.tokenService.hasMultipleCompanies();
    hasMultipleRolesAtCurrentCompany = this.tokenService.hasMultipleRolesAtCurrentCompany();
    uniqueCompanyTokenRoles: TokenRole[] = this.tokenService.uniqueCompanyTokenRoles();
    employee: Employee = this.auth.employee;
    currentCompanyTokenRoles: TokenRole[] = this.tokenService.tokensForCurrentCompany();
    canAccessCalendar: boolean =
        this.auth.can(Platform.timeOff.permission.calendar) &&
        this.auth.company.modules.some((m) => m.name === Platform.modules.timeOff);

    currentTokenRole: TokenRole = this.tokenService.activeToken();
    showSearch = false;
    showBeamerUnread = false;
    hasAsyncSystemTasksFlag = false;
    hasLanguageToggle = false;
    languages = Languages;
    employeeSearchCustomQuery: QueryFetcher = Employee.param('company', this.auth.company.id).where('accessible', true);
    hasTranslationDebugFlag = false;
    translationsDebugEnabled = false;
    transifexEnabled = false;
    THEME_PREFERENCES = Object.values(THEME_PREFERENCES);

    constructor(
        private auth: AuthService,
        private userAccess: UserAccessService,
        private router: Router,
        private tokenService: TokenService,
        private features: FeatureService,
        private translationsDebug: TranslateDebuggerStoreService,
        private transifexDebug: TransifexToggleService,
        private translationStorage: TranslationStorageService,
        private languageService: LanguageService,
        private notify: NotifyService,
        private mobileSidenavService: MobileSidenavService,
        public readonly themeService: ThemeService
    ) {}

    async ngOnInit(): Promise<void> {
        if (environment.enableBeamer) {
            this.initBeamer();
        }

        const [hasTranslationDebugFlag, hasAsyncSystemTasksFlag, hasLanguageToggle] = await this.features.hasMany(
            FeatureFlag.translationDebugToggle,
            FeatureFlag.asyncSystemTasks,
            FeatureFlag.useNgxTranslate
        );
        this.hasTranslationDebugFlag = hasTranslationDebugFlag;
        this.hasAsyncSystemTasksFlag = hasAsyncSystemTasksFlag;
        this.hasLanguageToggle = hasLanguageToggle;
        if (this.hasTranslationDebugFlag) {
            this.translationsDebugEnabled = this.translationsDebug.isDebugEnabled();
            this.transifexEnabled = this.transifexDebug.isTransifexEnabled();
        }
    }

    onToggleMenu(): void {
        this.mobileSidenavService.toggle();
    }

    onOpenSearch(): void {
        this.showSearch = true;
    }

    onCloseSearch(): void {
        this.showSearch = false;
    }

    logout(): void {
        this.userAccess.logout();
    }

    changeRole(roleId: number): void {
        // Do nothing if the role is unchanged
        if (this.currentTokenRole.roleId === roleId) {
            return;
        }
        this.userAccess.changeRole(roleId);
    }

    goToEmployeeProfile(employee: Employee): void {
        // For when it resets, we want to do nothing
        if (!employee) {
            return;
        }

        this.router.navigate(['/employees', 'directory', employee.id, 'personal']);
        this.onCloseSearch();
    }

    /**
     * When searching for someone and switching tabs, the search dropdown doesn't close.
     * By closing it on window blur, we fix the tab switching issue and prevent the menu from lingering
     */
    @HostListener('window:blur')
    closeEmployeeSearchPanel(): void {
        this.employeeSearch.closePanel();
    }

    chooseLanguage(language: string): void {
        // Do nothing if the language is unchanged
        if (this.employee.language === language) {
            return;
        }

        this.employee.language = language;
        this.employee
            .save()
            .then(() => {
                this.translationStorage.locale = this.employee.language;
                if (this.hasLanguageToggle) {
                    /**
                     * We need to reload the page so Transifex gets fresh content from ngx-translate
                     * Same approached used in account-show.view
                     * */
                    window.location.reload();
                } else {
                    this.languageService.translateTransifex(this.employee.language);
                }
            })
            .catch((err) => {
                this.notify.error(err.message);
            });
    }

    toggleTranslationDebug(): void {
        this.translationsDebug.toggleDebugEnabled();
        window.location.reload();
    }

    toggleTransifex(): void {
        this.transifexDebug.toggleTransifex();
        window.location.reload();
    }

    private initBeamer(): void {
        let role = 'employee';
        if (this.auth.hasDirectReports) {
            role = 'manager';
        }
        if (this.auth.isAdmin()) {
            role = 'admin';
        }

        /** Beamer said spaces should be fine, but also recommended using underscores instead */
        const modules = this.auth.company.modules.map((m) => role + '_' + m.name.replace(' ', '_'));
        const filter = [role, ...modules].join(';');

        const { firstName, lastName, account } = this.auth.employee ?? {};

        try {
            window.beamer_config = {
                product_id: environment.beamer,
                selector: '.beamer',
                lazy: false,
                counter: false,
                filter,
                user_firstname: firstName,
                user_lastname: lastName,
                user_email: account?.email,
                user_id: account?.id,
                callback: (count: number): void => {
                    if (count) {
                        this.showBeamerUnread = true;
                    }
                },
                onopen: (): void => {
                    this.showBeamerUnread = false;
                },
            };
            $.getScript('https://app.getbeamer.com/js/beamer-embed.js');
        } catch (e) {
            console.warn(e);
        }
    }
}

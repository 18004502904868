/**
 * Create a array of numbers incrementing by the starting number (default: 1)
 * @example
 *  const a = range(5)
 *  console.log(a) // [1, 2, 3, 4, 5]
 *
 *  // using a different starting base
 * const b = range(2, 0)
 * console.log(b) // [0, 1]
 */
const range = (length: number, starting = 1): number[] => new Array<number>(length).fill(starting).map((x, i) => x + i);

export const shadows = {
    inset: 'var(--inset-box-shadow)',
    card: 'var(--card-box-shadow)',
    dialog: 'var(--dialog-box-shadow)',
};

export const breakPoints = {
    sm: 680,
    md: 768,
    lg: 1024,
    xl: 1280,
} as const;

export const gridConf = {
    columns: 12,
    gutter: 24,
    mobileGutter: 16,
} as const;

export const gridRange = range(gridConf.columns);

export const sizeBasePixels = 8;

export const spacing = (factor: number): string => `${factor * sizeBasePixels}px`;

import { PaginatorConfig } from '@app/interfaces/paginator-config.interface';
import { ModelUnion as Model } from '@app/models/core/base.model';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { FilterValues } from '@app/modules/table/interfaces/filter-values.interface';
import { Metadata } from '@app/types/metadata.type';

export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export interface Sort {
    column: string;
    order: SortDirection;
}

export type IsLoadingFunc = (isLoading: boolean) => void;

export class Paginator<TValue = Model, TMeta = Record<string, unknown>> {
    private readonly _pageSize: number;
    private _baseQuery: QueryFetcher;
    private _keywords: string | null = null;
    private _sort: Sort = { column: 'id', order: SortDirection.DESC };
    private _currentValue: TValue[] = [];
    private _currentMeta?: Metadata<TMeta>;
    private _isLoading: Function;
    private _onError: Function;
    private _filters: FilterValues = {};
    private _page = 1;
    private _total = 0;
    // Used to avoid promise completion race conditions
    private _currentDataRetrievalPromise?: Promise<[TValue[], Metadata<TMeta>]>;
    private _currentDataRetrievalQuery: QueryFetcher;

    constructor(query: QueryFetcher<TValue>, options: PaginatorConfig = {}) {
        this._baseQuery = query;
        this._currentDataRetrievalQuery = query;
        this._pageSize = options.pageSize || 25;
        if (options.shouldAutoLoadData) {
            this.loadData();
        }
    }

    /**
     * Getter/Setter
     */
    get total(): number {
        return this._total;
    }

    get page(): number {
        return this._page;
    }

    set page(page: number) {
        if (this._page !== page) {
            this._page = page;
            this.loadData();
        }
    }

    get filters(): FilterValues {
        return this._filters;
    }

    set filters(filters: FilterValues) {
        this._filters = filters;
        this._page = 1;
        this.loadData();
    }

    get sort(): Sort {
        return this._sort;
    }

    get pageSize(): number {
        return this._pageSize;
    }

    get empty(): boolean {
        return !this.total;
    }

    set search(keywords: string | null) {
        this._keywords = keywords;
        this._page = 1;
        this.loadData();
    }

    get search(): string | null {
        return this._keywords;
    }

    get currentQuery(): QueryFetcher {
        return this._currentDataRetrievalQuery;
    }

    /**
     * Current value of the pagination page
     */
    get values(): TValue[] {
        return this._currentValue;
    }

    /**
     * Current metadata object
     */
    get meta(): Metadata<TMeta> | undefined {
        return this._currentMeta;
    }

    /**
     * Callback function for loading
     * @param {IsLoadingFunc} callback [description]
     */
    onLoading(callback: IsLoadingFunc): this {
        this._isLoading = callback;
        return this;
    }

    onError(callback: Function): this {
        this._currentValue = [];
        this._onError = callback;
        return this;
    }

    /**
     * Reset all the params and load the initial data
     */
    reset(): void {
        this._page = 1;
        this._filters = [];
        this.loadData();
    }

    /**
     * Load data for the paginator from the server
     */
    async loadData(): Promise<void> {
        this.onLoadStart();

        await this._currentDataRetrievalPromise;

        const query = this.cloneQuery();

        this.appendFilters(query);
        this.applyQuerySearch(query);

        query.page(this._page);
        query.orderBy(this._sort.column, this._sort.order);

        try {
            this._currentDataRetrievalQuery = query;
            this._currentDataRetrievalPromise = query.get();
            const [results, meta] = await this._currentDataRetrievalPromise;
            this.setMeta(results, meta);
            this._currentValue = results;
            this.onLoadEnd();
        } catch (err) {
            if (typeof this._onError === 'function') {
                this._onError(err);
                return;
            }
            throw new Error('Query failed and was not caught: ' + err.message, { cause: err });
        }
    }

    setSort(settings: Sort): void {
        this._sort = settings;
    }

    updateQuery(query: QueryFetcher): void {
        this._baseQuery = query;
    }

    private appendFilters(query: QueryFetcher): void {
        for (const filter of Object.keys(this._filters)) {
            query.where(filter, this._filters[filter]);
        }
    }

    private applyQuerySearch(query: QueryFetcher): void {
        if (this._keywords !== null && this._keywords.length) {
            query.where('query', this._keywords);
        }
    }

    private setMeta(results: TValue[], metaData: Metadata<TMeta>): void {
        if ('pagination' in metaData) {
            this._total = metaData.pagination.total;
            this._page = metaData.pagination.page;
            this._currentMeta = metaData;
        } else if ('page' in metaData) {
            this._total = +metaData.page.totalSize;
            this._page = +metaData.page.number;
            this._currentMeta = metaData;
        } else {
            this._total = results.length;
            this._page = 1;
        }
    }

    private onLoadStart(): void {
        if (typeof this._isLoading === 'function') {
            this._isLoading(true);
        }
    }

    private onLoadEnd(): void {
        if (typeof this._isLoading === 'function') {
            this._isLoading(false);
        }
    }

    private cloneQuery(): QueryFetcher {
        return this._baseQuery.clone();
    }
}

import { Avatarable } from '@app/interfaces/avatarable.interface';
import { TranslatableKey } from '@app/types/translatable.type';
import { format, isSameDay, parse, subMinutes } from 'date-fns';
import { Model } from '../core/base.model';
import { QueryFetcher } from '../core/query-fetcher.model';

export abstract class CalendarEvent extends Model {
    protected static _resource;
    protected static _version = 'v2';
    protected static entityFilters = {};
    abstract eventType: string;

    /**
     * Color used by Month Calendar
     */
    color = {
        background: `color-mix(in hsl, var(--primary-600), transparent 70%)`,
        text: `var(--primary-800)`,
        hoverBackground: `var(--primary-700)`,
        hoverText: `var(--grey-100)`,
    };

    hasToolTip = true;
    hasHover = true;

    entityFilters: object = {};

    // Component specific properties

    get type(): string {
        return this._attributes['type'];
    }

    get monthCalendarTitle(): string {
        return this.summary;
    }

    get monthCalendarTooltipDescription(): string {
        return this.description;
    }

    get upcomingEventTitle(): TranslatableKey {
        return this.summary;
    }

    set upcomingEventTitle(val: string) {
        this._attributes['summary'] = val;
    }

    get upcomingEventDescription(): TranslatableKey {
        return this.description;
    }

    set upcomingEventDescription(val: string) {
        this._attributes['description'] = val;
    }

    get summary(): string {
        return this._attributes['summary'];
    }

    get description(): string {
        return this._attributes['description'];
    }

    set description(val: string) {
        this._attributes['description'] = val;
    }

    get detail(): string {
        return this._attributes['detail'];
    }

    get status(): string {
        return this._attributes['status'];
    }

    get startAt(): Date {
        return this._attributes['startAt'];
    }

    get endAt(): Date {
        return this._attributes['endAt'];
    }

    get avatarId(): number {
        return this._attributes['avatarId'];
    }

    get entityId(): number {
        return this._attributes['entityId'];
    }

    get entityColor(): string {
        return this._attributes['entityColor'];
    }

    get firstName(): string {
        return this._attributes['firstName'];
    }

    get lastName(): string {
        return this._attributes['lastName'];
    }

    get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    /**
     * Used to fake an employee when we need to show an avatar
     */
    get pseudoEmployee(): Avatarable {
        return { avatarId: this.avatarId, firstName: this.firstName, lastName: this.lastName };
    }

    get dateDescription(): TranslatableKey | string {
        const sameDay = isSameDay(this.startAt, subMinutes(this.endAt, 1));
        if (sameDay) {
            return format(this.startAt, 'MMM DD');
        }

        return 'time-off-v3.time-off-events.timeOffdateDescription';
    }

    get isMultiDayEvent(): boolean {
        return !isSameDay(this.startAt, subMinutes(this.endAt, 1));
    }

    /**
     * This should be extended in derived classes that have path
     */
    get path(): (string | number)[] {
        return null;
    }

    // below are implemented to work with Angular Calendar
    get start(): Date {
        return parse(this.startAt);
    }

    get end(): Date {
        return subMinutes(parse(this.endAt), 1);
    }

    /**
     * Look on the calendar event type to see if it provides a filter for the related entity
     * If found, modify the query
     */
    static addApplicableFiltersToQuery(query: QueryFetcher, relatedEntities: Model[]): QueryFetcher {
        relatedEntities.forEach((relatedEntity) => {
            const entityType = relatedEntity.constructor['_resource'].split('/').pop();
            if (query._model.entityFilters[entityType]) {
                query._model.entityFilters[entityType](query, relatedEntity);
            }
        });

        return query;
    }
}

import { Paginator } from '@app/classes';
import { QueryFetcher } from '@app/models/core/query-fetcher.model';
import { Table } from '@app/modules/table/classes/table.class';
import { AuthService } from '@app/services';
import { T4Summary } from '@models/payroll/t4-summary.model';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs/operators';
import { GeneratedOnColumn, StatusColumn, TitleColumn } from './columns';

/**
 * An instance of Paginator that contains only unpersisted data. This is necessary for
 * users to be able to click into new tax returns, from where they will be able to
 * generate and persist them.
 *
 * We can add more / different records to this array as needed.
 */
class NonGeneratedTaxReturnPaginator extends Paginator<T4Summary> {
    _staticValues = [new T4Summary()];

    constructor() {
        super(new QueryFetcher(null));
    }

    get total(): number {
        return this._staticValues.length;
    }

    get empty(): boolean {
        return false;
    }

    get values(): T4Summary[] {
        return this._staticValues;
    }
}

export class TaxReturnsTable extends Table<T4Summary> {
    links = true;

    constructor(
        protected auth: AuthService,
        public year: number,
        private translateService: TranslateService
    ) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new TitleColumn(this.year, this.translateService), new GeneratedOnColumn(), new StatusColumn()];
    }

    /**
     * Attempt to load the T4Summary for the year.
     *
     * If the index is empty, swap the paginator for an instance of NonGeneratedTaxReturnPaginator,
     * so that we have our default row(s) in the table
     */
    setDataSource(): void {
        this.onLoading.pipe(filter((loading) => !loading)).subscribe(() => {
            if (this.paginator?.empty) {
                this.paginator = new NonGeneratedTaxReturnPaginator();
            }
        });

        this.paginator = new Paginator(
            T4Summary.param('company', this.auth.company?.id).where('year', this.year.toString())
        );
    }

    getLink(t4Summary: T4Summary): string[] {
        return ['/payroll/records/tax-returns', this.year.toString(), t4Summary.id?.toString() ?? 'new'];
    }
}

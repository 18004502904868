import { Injectable } from '@angular/core';
import {
    BasicColor,
    Color,
    Shade,
    getCSSVariable,
    getColorValue,
    getHexColorValue,
} from '@humi-design-library/theme/palette';

/**
 * Used to turn colors from sass/css into JS variables
 */
@Injectable({ providedIn: 'root' })
export class ColorService {
    private rootStyle: CSSStyleDeclaration;
    private _getColorValue: (color: Color | BasicColor, shade?: Shade) => string;
    private _getHexColorValue: (color: Color | BasicColor, shade?: Shade) => string;
    private _getCSSVariable: (variable: `--${string}`) => string;
    constructor() {
        this.rootStyle = getComputedStyle(document.body);
        this._getColorValue = getColorValue(this.rootStyle);
        this._getHexColorValue = getHexColorValue(this.rootStyle);
        this._getCSSVariable = getCSSVariable(this.rootStyle);
    }

    getColorValue(color: Color, shade: Shade): string;
    getColorValue(color: BasicColor): string;
    getColorValue(color: Color | BasicColor, shade?: Shade): string {
        return this._getColorValue(color, shade);
    }

    getCSSVariable(variable: `--${string}`): string {
        return this._getCSSVariable(variable);
    }

    getHexColorValue(color: Color, shade: Shade): string;
    getHexColorValue(color: BasicColor): string;
    getHexColorValue(color: Color | BasicColor, shade?: Shade): string {
        return this._getHexColorValue(color, shade);
    }
}

<div id="table-wrapper" [ngStyle]="height ? { height: height, 'overflow-y': 'auto' } : {}">
    <table class="ui table" *ngIf="reportingServiceData?.datasets">
        <thead>
            <tr>
                <th>{{ label ?? 'Label' }}</th>
                <th *ngFor="let dataset of reportingServiceData.datasets">{{ dataset.label }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let label of reportingServiceData.labels; let index = index">
                <td>{{ label }}</td>
                <td *ngFor="let dataset of reportingServiceData.datasets">
                    <ng-container *ngIf="reportSlug === 'salary'">
                        {{ dataset.data[index] | currency: '' : 'symbol' : '1.2-2' }}
                    </ng-container>
                    <ng-container *ngIf="reportSlug !== 'salary'"> {{ dataset.data[index] }} </ng-container>
                </td>
            </tr>
        </tbody>
    </table>
</div>

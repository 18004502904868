import { Paginator } from '@app/classes';
import { TimeOffType } from '@app/models/time-off-v3/time-off-type.model';
import { Table } from '@app/modules/table/classes/table.class';
import { MenuColumnItem } from '@app/modules/table/interfaces/menu-column-item.interface';
import { AuthService } from '@app/services';
import { NameColumn } from './columns/name';
import { TimeOffPoliciesCount } from './columns/time-off-policies-count';

export class TimeOffTypesTable extends Table {
    static editOption: MenuColumnItem = {
        label: 'time-off-v3.timeOffTypesTable.editOption',
        event: 'edit-type',
    };

    static deleteOption: MenuColumnItem = {
        label: 'time-off-v3.timeOffTypesTable.deleteOption',
        event: 'delete-type',
    };

    showMenuColumnOptions = true;

    constructor(auth: AuthService) {
        super(auth);
        this.boot();
    }

    setColumns(): void {
        this.columns = [new NameColumn(), new TimeOffPoliciesCount()];
    }

    setDataSource(): void {
        this.paginator = new Paginator(TimeOffType.with(['timeOffPolicies']));
    }

    getMenuColumnOptionsForRow(timeOffType: TimeOffType): MenuColumnItem[] {
        return [TimeOffTypesTable.editOption, TimeOffTypesTable.deleteOption];
    }
}

import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-chart-data-table',
    templateUrl: './chart-data-table.template.html',
    styleUrls: ['./chart-data-table.style.scss'],
})
export class ChartDataTableComponent {
    @Input() reportingServiceData: any;
    @Input() reportSlug = '';
    @Input() label: string;
    @Input() height?: string;
}

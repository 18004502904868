import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver } from '@angular/cdk/layout';
import { CdkPortalOutlet, PortalOutlet } from '@angular/cdk/portal';
import { Component, DestroyRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';
import { AppResources } from '@app/app.resources';
import { Platform } from '@app/classes';
import { AnalyticEvents, FeatureFlag } from '@app/enums';
import { NotificationEvents } from '@app/enums/notification-events.enum';
import { MenuItem, MenuItemGroup, MenuItemNames } from '@app/interfaces';
import { AdminNavMenu } from '@app/menus/admin-nav.menu';
import { EmployeeNavMenu } from '@app/menus/employee-nav.menu';
import { OnboardingStatusService } from '@app/modules/self-serve/services/onboarding-status.service';
import {
    AbilityService,
    AnalyticService,
    AuthService,
    FeatureAcknowledgementService,
    NotificationService,
    TokenService,
    UserAccessService,
    ZenDeskService,
} from '@app/services';
import { Features } from '@app/services/feature-acknowledgement.service';
import { FeatureService } from '@app/services/feature.service';
import { MobileSidenavService } from '@app/services/mobile-sidenav.service';
import { TasksService } from '@app/services/tasks.service';
import { TokenRole } from '@app/services/token.service';
import { breakPoints } from '@app/styles/theme';
import { LDFlagSet } from 'launchdarkly-js-client-sdk';
import { Subject } from 'rxjs';
import { filter, takeUntil, takeWhile } from 'rxjs/operators';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.template.html',
    styleUrls: ['./sidebar.style.scss'],
    animations: [
        trigger('fadeInOut', [
            state('fadeOut', style({ opacity: 0 })),
            state('fadeIn', style({ opacity: 1 })),
            transition('fadeIn => fadeOut', [animate('.2s')]),
            transition('fadeOut => fadeIn', [animate('.2s')]),
        ]),
    ],
})
export class SidebarComponent implements OnInit, OnDestroy {
    @ViewChild(CdkPortalOutlet, { static: false }) portalOutlet!: PortalOutlet;
    isMobileExpanded = false;
    isDesktopExpanded = true;
    isExtrasExpanded = false;
    isMobileCompanyDrawerExpanded = false;
    navigation: MenuItemGroup[] = [];
    featureFlags!: LDFlagSet;
    employee = this.auth.employee;
    company = this.auth.company;
    currentCompanyTokenRoles: TokenRole[] = this.tokenService.tokensForCurrentCompany();
    uniqueCompanyTokenRoles: TokenRole[] = this.tokenService.uniqueCompanyTokenRoles();
    hasMultipleCompanies: boolean = this.tokenService.hasMultipleCompanies();
    hasMultipleRolesAtCurrentCompany: boolean = this.tokenService.hasMultipleRolesAtCurrentCompany();
    currentTokenRole: TokenRole = this.tokenService.activeToken();

    helpCenterUrl = AppResources.HelpCenter;
    supportTicketUrl = AppResources.SupportTicket;
    hasAsyncSystemTasksFlag = false;
    companyHasTenantId = false;

    /**
     * portalHasContent indicates if the cdkPortalOutlet has content attached to it
     * if so, we show that content instead of the regular sidebar content
     */
    portalHasContent = false;

    isCollapseButtonVisible = false;

    readonly sideBarNavigationLabel = 'Side navigation';

    private _onDestroy = new Subject<void>();
    private _hasUnread = false;

    constructor(
        private abilities: AbilityService,
        private analyticService: AnalyticService,
        private auth: AuthService,
        private breakpointObserver: BreakpointObserver,
        private featureAcknowledgementService: FeatureAcknowledgementService,
        private featureService: FeatureService,
        private mobileSidenavService: MobileSidenavService,
        private notificationService: NotificationService,
        private tasksService: TasksService,
        private tokenService: TokenService,
        private userAccess: UserAccessService,
        private activatedRoute: ActivatedRoute,
        private destroyRef: DestroyRef,
        private onboardingStatusService: OnboardingStatusService,
        public zenDeskService: ZenDeskService
    ) {
        this.mobileSidenavService.mobileSidenavStatus$
            .pipe(takeUntilDestroyed())
            .subscribe((mobileSidenavStatus) => (this.isMobileExpanded = mobileSidenavStatus.isOpen));

        // if there's an active quickstart, we want to listen for it possibly being completed
        // once completed, we need to reload the side nav
        if (this.onboardingStatusService.hasActiveQuickstart()) {
            this.onboardingStatusService.onboardingStatus$
                .pipe(
                    filter((onboardingStatus) => onboardingStatus !== null),
                    takeUntilDestroyed(),
                    takeWhile((onboardingStatus) => Boolean(onboardingStatus && !onboardingStatus.isComplete), true)
                )
                .subscribe(() => this.loadNavMenu());
        }
    }

    get hasUnread(): boolean {
        return this._hasUnread || this.tasksService.totalIncompleteUserTasks > 0;
    }

    get isMobile(): boolean {
        return !this.breakpointObserver.isMatched(`(min-width: ${breakPoints.sm}px)`);
    }

    async ngOnInit(): Promise<void> {
        this.companyHasTenantId = this.auth.company?.prTenantId != null;
        await this.loadNavMenu();
        this.decorateNavigation();

        this.auth.onHydrate.subscribe((_) => {
            this.loadNavMenu();
        });

        this.featureAcknowledgementService.acknowledgedFeaturesChanged.subscribe(async () => {
            this.decorateNavigation();
        });

        this.hasAsyncSystemTasksFlag = await this.featureService.has(FeatureFlag.asyncSystemTasks);

        this.notificationService.events$
            .pipe(takeUntil(this._onDestroy))
            .subscribe((event: NotificationEvents) => this.updateAlertIcon(event));

        this.updateAlertIcon();
    }

    ngAfterViewInit(): void {
        this.listenForPortalChanges();
    }

    ngOnDestroy(): void {
        this._onDestroy.next();
        this._onDestroy.complete();
    }

    logout(): void {
        this.userAccess.logout();
    }

    changeRole(roleId: number): void {
        this.userAccess.changeRole(roleId);
    }

    onToggleMobileDrawer(): void {
        this.mobileSidenavService.toggle();
    }

    onToggleMobileCompanyDrawer(): void {
        this.isMobileCompanyDrawerExpanded = !this.isMobileCompanyDrawerExpanded;
    }

    // The desktop drawer does not need to emit its state as its handled entirely within this component
    onToggleDesktopDrawer(): void {
        this.isDesktopExpanded = !this.isDesktopExpanded;
    }

    onToggleExtras(): void {
        this.isExtrasExpanded = !this.isExtrasExpanded;
    }

    trackAddOnsEvent(navKey: string): void {
        if (navKey === 'sidebar.addOns') {
            this.analyticService.trackEvent(AnalyticEvents.ClickAddOnsModule);
        }
    }

    getCompanyDrawerExpandedState(): string {
        const MAX_VISIBLE_COMPANIES = 4;
        const CLASS_PREFIX = 'expand-';

        if (!this.isMobileCompanyDrawerExpanded) {
            return '';
        }

        if (this.uniqueCompanyTokenRoles.length >= MAX_VISIBLE_COMPANIES) {
            return `${CLASS_PREFIX}${MAX_VISIBLE_COMPANIES}`;
        }

        return `${CLASS_PREFIX}${this.uniqueCompanyTokenRoles.length}`;
    }

    private updateAlertIcon(event: NotificationEvents | null = null): boolean | void {
        switch (event) {
            case NotificationEvents.allAreRead:
                return (this._hasUnread = false);
            case NotificationEvents.isUnRead:
                return (this._hasUnread = true);
            default:
                return this.refreshHasUnread();
        }
    }

    private refreshHasUnread(): void {
        this.notificationService.getUnreadNotificationsCount().then((count: number) => (this._hasUnread = count > 0));
    }

    private async loadNavMenu(): Promise<void> {
        this.featureFlags = await this.featureService.all();
        const navMenu: MenuItemGroup[] = this.auth.isAdmin() ? [...AdminNavMenu] : [...EmployeeNavMenu];

        this.navigation = this.filterNavigation(navMenu);
    }

    /**
     * Filter the sidebar navigation menu based on menu item rules
     */
    private filterNavigation(navigation: MenuItemGroup[]): MenuItemGroup[] {
        return navigation
            .map((group: MenuItemGroup) =>
                group
                    .filter((navItem: MenuItem) => (navItem.can ? this.auth.can(navItem.can) : true))
                    .filter((navItem: MenuItem) => (navItem.ableTo ? this.abilities[navItem.ableTo]() : true))
                    .filter((navItem: MenuItem) => (navItem.unableTo ? !this.abilities[navItem.unableTo]() : true))
                    .filter((navItem: MenuItem) =>
                        navItem.showIfHasFeatureFlag ? this.featureFlags[navItem.showIfHasFeatureFlag] : true
                    )
                    .filter((navItem: MenuItem) =>
                        navItem.hideIfHasFeatureFlag ? !this.featureFlags[navItem.hideIfHasFeatureFlag] : true
                    )
                    .filter((navItem: MenuItem) =>
                        navItem.module
                            ? this.auth.company &&
                              this.auth.company.modules.findIndex((m) => m.name === navItem.module) > -1
                            : true
                    )
                    .filter((navItem: MenuItem) =>
                        navItem.showWhenModuleDisabled
                            ? this.auth.company?.modules.findIndex((m) => m.name === navItem.showWhenModuleDisabled) ===
                              -1
                            : true
                    )
                    .filter((navItem: MenuItem) =>
                        navItem.employeeModule ? this.auth.employee?.hasModule(navItem.employeeModule) : true
                    )
                    .filter((navItem: MenuItem) =>
                        navItem.showWhen
                            ? navItem.showWhen({
                                  authService: this.auth,
                                  featureService: this.featureService,
                                  abilityService: this.abilities,
                                  activateRoute: this.activatedRoute,
                                  onboardingStatusService: this.onboardingStatusService,
                              })
                            : true
                    )
                    .filter((navItem: MenuItem) => (navItem.hideIfNoTenantId ? this.companyHasTenantId : true))
                    .filter((navItem: MenuItem) => {
                        if (navItem.module !== Platform.modules.benefits) {
                            return true;
                        }
                        return this.doesBenefitLinkFilterPasses(navItem);
                    })
            )
            .filter((group: MenuItemGroup) => {
                return group.length > 0;
            });
    }

    private doesBenefitLinkFilterPasses(navItem: MenuItem): boolean {
        if (this.auth.isAdmin()) {
            if (navItem.showIfCompanyHasSimplyBenefits && !this.auth.company?.simplyBenefitsAccountId) {
                return false;
            }

            if (
                this.featureFlags[FeatureFlag.simplyBenefits] &&
                !navItem.showIfCompanyHasSimplyBenefits &&
                this.auth.company?.simplyBenefitsAccountId
            ) {
                return false;
            }

            return true;
        }

        if (navItem.showIfCompanyAndEmployeeHaveSimplyBenefits && !this.companyAndEmployeeHaveSimplyBenefits()) {
            return false;
        }

        if (
            this.featureFlags[FeatureFlag.simplyBenefits] &&
            !navItem.showIfCompanyAndEmployeeHaveSimplyBenefits &&
            this.companyAndEmployeeHaveSimplyBenefits()
        ) {
            return false;
        }

        return true;
    }

    private companyAndEmployeeHaveSimplyBenefits(): boolean {
        if (this.auth.company?.simplyBenefitsAccountId && this.auth.employee?.hasSimplyBenefits) {
            return true;
        }
        return false;
    }

    /**
     * Modify the nav menu after it has been generated.
     * This needs to happen here because the nav menu is generated in a file
     * that does not have access to the dependency injection container.
     *
     * Right now, we are modifying an attribute on the benefits upsell menu item.
     */
    private async decorateNavigation(): Promise<void> {
        for (const navGroup of this.navigation) {
            for (const navItem of navGroup) {
                if (navItem.name === MenuItemNames.benefitsUpsell) {
                    const hasAcknowledgedFeature = await this.featureAcknowledgementService.hasAcknowledgedFeature(
                        Features.benefitsUpsell
                    );

                    navItem.showIndicator = !hasAcknowledgedFeature;
                }

                if (navItem.name === MenuItemNames.inAppUpsell) {
                    navItem.showIndicator = !(await this.featureAcknowledgementService.hasAcknowledgedFeature(
                        Features.inAppUpsell
                    ));
                }
            }
        }

        this.navigation = [...this.navigation];
    }

    private listenForPortalChanges(): void {
        this.mobileSidenavService.portal$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((portal) => {
            this.portalOutlet?.detach();
            if (portal) {
                this.portalOutlet.attach(portal);
            }

            this.portalHasContent = this.portalOutlet.hasAttached();
        });
    }
}

import { CalendarEvent } from '@models/common/calendar-event.model';
import { Moment } from 'moment';

interface TimeOffDayPayload {
    description?: string;
    startAt: Moment | Date;
    endAt: Moment | Date;
}

export class TimeOffDayCalendarEvent extends CalendarEvent {
    eventType = 'day';
    hasToolTip = false;
    hasHover = false;

    color = {
        background: `transparent`,
        text: `var(--primary-700)`,
        hoverBackground: `transparent`,
        hoverText: `var(--primary-700)`,
    };

    private payload: TimeOffDayPayload;

    constructor(res: TimeOffDayPayload) {
        super(res);
        this.payload = res;
    }

    get monthCalendarTitle(): string {
        return this.payload.description;
    }
}

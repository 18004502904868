import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, Input, OnDestroy } from '@angular/core';
import { MatLegacyMenu } from '@angular/material/legacy-menu';

export type ButtonTypes = 'primary' | 'ghost' | 'link' | 'outline' | 'danger' | 'danger-ghost' | 'icon' | 'filter';
export type ButtonSizes = null | 'compact' | 'no-padding' | 'mobile-shrink' | 'full-width' | 'stripped' | 'min-padding';

@Component({
    selector: 'ui-button',
    templateUrl: './button.template.html',
    styleUrls: ['./button.style.scss'],
})
export class ButtonComponent implements OnDestroy {
    @Input()
    set submit(val: boolean) {
        this._submit = coerceBooleanProperty(val);
    }

    get submit(): boolean {
        return this._submit;
    }

    /**
     * button display type
     */
    @Input() type: ButtonTypes = 'ghost';

    @Input() size: ButtonSizes = null;
    @Input() url = '';
    @Input() queryParams: { [key: string]: primitive } = {};
    /**
     * Link is off app, does not utilize the ng-router
     */
    @Input() externalLink = false;
    @Input() openInNewTab = true; // Rarely an external link will need to be opened within the same tab (ie. navigating to Unified for an integration)
    @Input() tabIndexValue = '';
    @Input() loading = false;
    @Input() matMenu: MatLegacyMenu | null = null;
    @Input('aria-label') ariaLabel: string | null = null;

    @Input() get disabled(): boolean {
        return this._disabled;
    }

    set disabled(value: boolean) {
        this._disabled = coerceBooleanProperty(value);
    }

    private _disabled = false;
    private _submit = false;

    constructor(private elementRef: ElementRef) {
        this.elementRef.nativeElement.addEventListener('click', this.stopDisabledEventPropagation.bind(this));
    }

    ngOnDestroy(): void {
        this.elementRef.nativeElement.removeEventListener('click', this.stopDisabledEventPropagation.bind(this));
    }

    get buttonClass(): string {
        const classes: string[] = [this.type];

        if (this.size) {
            classes.push(this.size);
        }

        return classes.join(' ');
    }

    /**
     * Used to stop click events added to the host element when a button is disabled
     */
    private stopDisabledEventPropagation(event: Event): void {
        if (this.disabled) {
            event.stopImmediatePropagation();
        }
    }
}
